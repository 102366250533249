import {
  Stack,
  Box,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";

import { COLORS } from "constants";
import setupIcon from "assets/images/setup.png";
import recordIcon from "assets/images/record.png";
import publishIcon from "assets/images/publish.png";
import { Fragment } from "react";
import { homePageStyle } from "components/common/Styles";
import { useNavigate } from "react-router-dom";

function KnowMoreSection() {
  let navigate = useNavigate();
  const isXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));

  return (
    <Box>
      <Box sx={homePageStyle.knowMoreSection}>
        <Stack spacing={2}>
          <Typography
            variant={isXs ? "font25b" : "font35b"}
            color={COLORS.primary}
          >
            Know more about My Audiobook Recorder
          </Typography>
          <Typography variant={isXs ? "font15" : "font20"} color={COLORS.grey}>
            Three key steps to master the audiobook world
          </Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={10}
            pt={7}
            px={10}
            justifyContent="center"
          >
            {[
              { label: "Setup", icon: setupIcon, divParam: "setup" },
              { label: "Record", icon: recordIcon, divParam: "record" },
              { label: "Publish", icon: publishIcon, divParam: "publish" },
            ].map((value) => (
              <Fragment key={value.label}>
                <Stack
                  py={5}
                  px={3}
                  spacing={4}
                  sx={{
                    border: "2px solid #ccc",
                  }}
                >
                  <Stack spacing={4} px={7}>
                    <img
                      src={value.icon}
                      style={{ width: "90px" }}
                      alt="merge para"
                    />
                    <Typography
                      variant="font25b"
                      align="center"
                      color={COLORS.primary}
                    >
                      {value.label}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack spacing={4} px={{ xs: 2, md: 7 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        navigate(`/how-its-works?div=${value.divParam}`);
                      }}
                    >
                      <Typography variant="font12" color={COLORS.black} noWrap>
                        Read more
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Fragment>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default KnowMoreSection;
