import { Skeleton } from "@mui/material";
import React, { useState, useEffect, useRef, Fragment } from "react";

const ImageComponent = ({ src, style, alt }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { rootMargin: "50px", threshold: 0.1 } // Adjust rootMargin to load images earlier
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible && src) {
      const img = new Image();
      img.src = src;

      const timeout = setTimeout(() => {
        setLoading(false);
      }, 5000);

      img.onload = () => {
        setLoading(false);
        clearTimeout(timeout);
      };

      img.onerror = () => {
        setLoading(false);
        setError(true);
      };

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isVisible]);

  if (error) {
    return <p>Error loading image!</p>;
  }

  return (
    <Fragment>
      {loading && (
        <Skeleton variant="rectangular" width={"100%"} height={120} />
      )}
      <img
        ref={imgRef}
        loading="lazy"
        src={isVisible ? src : undefined}
        style={style}
        alt={alt}
      />
    </Fragment>
  );
};

export default ImageComponent;
