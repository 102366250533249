import { apiCall } from "service/AuthProvider";

const SERVICE_URL = "project-service/";

export const listProjects = async (searchParams) => {
  const url = `${SERVICE_URL}${searchParams}`;
  return apiCall("get", url);
};

export const createProject = async (body) => {
  const url = `${SERVICE_URL}projects`;
  return apiCall("post", url, body);
};

export const getProject = async (projectId) => {
  const url = `${SERVICE_URL}projects/${projectId}`;
  return apiCall("get", url);
};

export const updateProject = async (projectId, body) => {
  const url = `${SERVICE_URL}projects/${projectId}`;
  return apiCall("put", url, body);
};

export const deleteProject = async (projectId) => {
  const url = `${SERVICE_URL}projects/${projectId}`;
  return apiCall("delete", url);
};
