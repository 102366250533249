import React, { useEffect } from "react";
import { Typography, Box, Grid, Paper } from "@mui/material";
import AdminAuthorQuickSearch from "./sections/AdminAuthorQuickSearch";
import AdminProjectQuickSearch from "./sections/AdminProjectQuickSearch";
import AdminDashboardSummary from "./sections/AdminDashboardSummary";
import { COLORS } from "constants";
import { getDashboardData } from "service/DashboardService";
import { useStateUpdate } from "UseStateUpdate";
import AdminEngineerQuickSearch from "./sections/AdminEngineerQuickSearch";

function AdminDashboard() {
  const dashBoardData = useStateUpdate();

  useEffect(() => {
    callGetDashBoardData();
  }, []);

  const callGetDashBoardData = async () => {
    const result = await getDashboardData();
    if (result.status === 200) {
      const { data } = result.data;
      dashBoardData.update(data);
    }
  };

  return (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Dashboard
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          {[
            <AdminAuthorQuickSearch key={`author`} />,
            <AdminProjectQuickSearch key={`project`} />,
            <AdminEngineerQuickSearch key={`engineer`} />,
          ].map((searchComponent) => (
            <Grid item xs={12} sm={6} md={4} key={searchComponent.key}>
              <Paper variant="outlined" sx={{ width: "100%" }}>
                <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
                  {searchComponent}
                </Box>
              </Paper>
            </Grid>
          ))}
          <AdminDashboardSummary {...{ dashBoardData }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDashboard;
