import React from "react";
import { Modal, Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { largeModalStyle } from "components/common/Styles";
import { COLORS } from "constants";
import groupingScreenshot from "assets/images/groupingScreenshot.png";
import AddChapter from "assets/images/groupingScreenAddChapter.png";
import editChapter from "assets/images/editChapter.png";
import groupingScreenshotResetAll from "assets/images/groupingScreenshotResetAll.png";
import groupingScreenCompleteButton from "assets/images/groupingScreenCompleteButton.png";
import groupScreenAutogroupbtn from "assets/images/groupScreenAutogroupbtn.png";

function GroupingGuideLine(props) {
  const { openGuideLineModal } = props;

  const handleClose = () => {
    openGuideLineModal.update(false);
  };

  return (
    <Modal open={openGuideLineModal.state} onClose={handleClose}>
      <Box sx={largeModalStyle}>
        <Grid container justifyContent="flex-end" px={1}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box width="100%" p={4}>
          <Stack spacing={2}>
            <Typography variant="font35b" color={COLORS.primary} align="center">
              Group chapter
            </Typography>
            <Typography variant="font32b" align="center">
              Guidelines
            </Typography>
            <Box width="100%">
              <img
                src={groupingScreenshot}
                width="100%"
                alt="groupingScreenshot"
              />
            </Box>
            <Typography variant="font25b">1. Select pages:</Typography>
            <Typography variant="font20" pl={5}>
              Select the pages you wish to group by clicking on the page.The
              selection limit is 30 pages.
            </Typography>
            <Typography variant="font25b">2. Add chapter:</Typography>
            <Typography variant="font20" pl={5}>
              {
                " When you are happy with your selection. Click on the add chapter button to group them into a single chapter. "
              }
              <img src={AddChapter} width="20%" alt="AddChapter" />
            </Typography>
            <Typography variant="font25b">3. View chapter:</Typography>
            <Typography variant="font20" pl={5}>
              You can browse the pages in a chapter by selecting them from the
              chapter list. You can close the selection using the close button
              on the top right side of the screen.
            </Typography>{" "}
            <Typography variant="font25b">
              4. Reset the last chapter:
            </Typography>
            <Typography variant="font20" pl={5}>
              {"Click on the reset button "}
              <img src={editChapter} width="25px" alt="editChapter" />
              {" on the last chapter card to reset the last chapter."}
            </Typography>{" "}
            <Typography variant="font25b">5. Reset all:</Typography>
            <Typography variant="font20" pl={5}>
              {
                "Use this button to reset all the pages. You will have to start grouping from chapter 1 again. "
              }
              <img
                src={groupingScreenshotResetAll}
                width="15%"
                alt="groupingScreenshotResetAll"
              />
            </Typography>
            <Typography variant="font25b">6. Auto-group chapters:</Typography>
            <Typography variant="font20" pl={5}>
              {
                "Click this button to automatically group chapters. You can auto-group from anywhere you want."
              }
              <img
                src={groupScreenAutogroupbtn}
                width="25%"
                alt="groupingScreenCompleteButton"
              />
            </Typography>
            <Typography variant="font25b">7. Grouping completed:</Typography>
            <Typography variant="font20" pl={5}>
              {
                "After completely grouping all the pages, click on the complete button to proceed to the assign author. "
              }
              <img
                src={groupingScreenCompleteButton}
                width="15%"
                alt="groupingScreenCompleteButton"
              />
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

export default GroupingGuideLine;
