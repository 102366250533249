import React, { useState, useContext } from "react";
import {
  Modal,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  confirmModalIcon,
  pageNumberIndicator,
  pageShowingCard,
  primaryButtonHover,
  unRecordedPagesModalStyle,
} from "../common/Styles";
import warningIcon from "assets/images/warningOrange.png";
import { deleteBlankPages } from "service/PageService";
import { COLORS } from "constants";
import ImageComponent from "components/common/ImageComponent";
import { getImageUrl } from "utils/GetUrl";
import { VerifyPageContext } from "context/context";
function BlankPagesModal({ open, blankPages, onClose, callVerifyAllPages }) {
  const { pages, selectedIndex } = useContext(VerifyPageContext);
  const classes = pageNumberIndicator();
  const classes2 = pageShowingCard();

  //   const handleDeleteBlankPages = async () => {
  //     for (const page of blankPages) {
  //       //await deletePage(page.pageId); // Assume a deletePage API exists
  //     }
  //     setOpenBlankPagesModal(false);
  //     fetchBookDetails();
  //   };

  //   const deleteBlankPages = () => {
  //     const blankPageIds = blankPages.map((page) => page.pageId);
  //     handleDeleteBlankPages(blankPageIds);
  //   };
  const handleDeleteBlankPages = async () => {
    const body = { pageIds: blankPages };

    try {
      const response = await deleteBlankPages(body);
      if (response.status === 200) {
        selectedIndex.update(1);
        await callVerifyAllPages();
      }
      console.log("Deleting blank pages...");
    } catch (error) {
      console.log("Error deleting blank pages:", error);
    }

    onClose();
  };

  return (
    <Modal open={open}>
      <Box sx={{ ...unRecordedPagesModalStyle }}>
        <Stack
          spacing={4}
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Stack spacing={1} alignItems="center" sx={{ width: "100%" }}>
            <Grid
              container
              justifyContent="flex-end"
              px={1}
              sx={{ position: "absolute" }}
            >
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <img src={warningIcon} style={confirmModalIcon} alt="icon" />
            <Typography variant="font20" textAlign="center">
              Blank pages detected
            </Typography>
            <Typography variant="font16" width={"100%"} textAlign="center">
              The following pages are blank. If they are unnecessary, you can
              delete them by clicking <b>Delete and verify all</b>.
            </Typography>
          </Stack>
          <Box
            sx={{
              bgcolor: "#EAEFF1",
              width: "100%",
              height: "100vh",
              p: 4,
              overflow: "auto",
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              justifyContent={"center"}
            >
              {pages?.state
                ?.filter((page) => blankPages.includes(page.pageId))
                ?.map((page) => (
                  <Grid item key={page.pageId}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        position: "relative",
                      }}
                      className={classes2.groupingPageRight}
                    >
                      <Box className={classes.groupingPageLeft}>
                        <Typography variant="font12b" color={COLORS.white}>
                          {pages.state.findIndex(
                            (p) => p.pageId === page.pageId
                          )}
                        </Typography>
                      </Box>
                      <Grid container>
                        <ImageComponent
                          src={getImageUrl(page.pageImageUrl)}
                          style={{
                            width: "100%",
                            height: `100%`,
                            borderRadius: "10px",
                          }}
                          alt="page-image"
                        />
                      </Grid>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Stack direction="row" spacing={3}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeleteBlankPages}
              sx={primaryButtonHover}
            >
              <Typography variant="font15" noWrap>
                Delete and verify all
              </Typography>
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={onClose}
              sx={primaryButtonHover}
            >
              <Typography variant="font15" noWrap>
                Cancel
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default BlankPagesModal;
