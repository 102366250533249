import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { COLORS } from "constants";

function BookName({ bookName }) {
  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={1}>
        <Typography variant="font18">Book name :</Typography>
        <Typography variant="font18b" color={COLORS.primary}>
          {bookName}
        </Typography>
      </Stack>
    </Grid>
  );
}

export default BookName;
