import { Stack, Box, Typography, useMediaQuery, Grid } from "@mui/material";
import { COLORS } from "constants";
import audibleLogo from "assets/images/Audible_logo.png";
import audiobooklogo from "assets/images/audiobookcom.png";
import acxLogo from "assets/images/acxlogo.png";
import authorsRepublicLogo from "assets/images/ar-logo.png";
import iTunesLogo from "assets/images/itunes.png";
import { homePageStyle } from "components/common/Styles";

function PlatformSection() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));

  return (
    <Box height="85vh">
      <Box sx={homePageStyle.platform}>
        <Stack spacing={4}>
          <Typography variant={isXs ? "font25" : "font35"} color={COLORS.white}>
            Publish your audiobooks with an effortless transition to the best
            platforms in the market.
          </Typography>
          <Typography variant={isXs ? "font15" : "font20"} color={COLORS.white}>
            My Audio book values your creation to be brought to fruition. We
            assist in your publishing efforts every step of the way. We
            encourage versatility and are proud of the compatibility across most
            distribution platforms.
          </Typography>
          <Grid
            container
            bgcolor="#BFE6F5"
            borderRadius={50}
            justifyContent="center"
            alignContent="center"
            p={1}
          >
            <Grid item xs={4} sm={2}>
              <img
                src={audibleLogo}
                style={{ width: "90%" }}
                alt="audibleLogo"
              />
            </Grid>

            <Grid item xs={4} sm={2}>
              <img
                src={audiobooklogo}
                style={{ width: "90%" }}
                alt="audiobooklogo"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <img src={acxLogo} style={{ width: "90%" }} alt="acxLogo" />
            </Grid>
            <Grid item xs={4} sm={2}>
              <img
                src={authorsRepublicLogo}
                style={{ width: "90%" }}
                alt="authorsRepublicLogo"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <img src={iTunesLogo} style={{ width: "90%" }} alt="iTunesLogo" />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
}

export default PlatformSection;
