import { Stack, Box, Typography, Container, colors } from "@mui/material";
import { COLORS } from "constants";
import acxScreenshot from "assets/images/acx.png";

function PublishBook() {
  return (
    <Box>
      <Container maxWidth="md">
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font40b"} color={COLORS.primary}>
              Step 3: Publish your book
            </Typography>
          </Stack>
        </Box>
        {/* <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font35b"}>Options</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              There are so many options out there for an audiobook publication,
              but none as huge as ACX. It’s worth mentioning that some options
              are slowly gaining traction towards reachability and efficient
              yielding, but it’s also worth noting that you’re going to have to
              choose a non-exclusive publication deal with ACX to go on any of
              these platforms. This means you will receive significantly lower
              ACX royalties.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              <b>Author’s Republic</b> is another company that is a distributor
              of audiobooks and they have varied compensation of royalties due
              to the different types of retailers involved. Author’s Republic’s
              royalty rates differ by partner – some use the author provided
              MSRP, some are rental prices and some are library prices. The
              company shares that they have very relaxed publisher agreement
              terms and that the customers can terminate their agreement with
              them at any time if they so choose.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              <b>Findaway Voices</b> hosts an open audiobook market where
              authors and publishers choose where to sell their audiobooks and
              at what price to sell them. They’ve built a robust, global
              distribution network that makes it easy for authors to sell their
              audiobooks through more than 20 audiobook sellers across retail,
              library, and school markets.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              There are other big players in this market like
              <b>Overdrive, Audiobooks.com and Downpour</b>. Even though
              Overdrive has a very selective approach in choosing which authors
              to accept, they have good channels for wider reach and
              distribution. Audiobooks.com is in second place behind ACX in
              terms of audiobook sales. Downpour is a similar company to
              Audiobooks.com where you have to negotiate a deal with them to
              have your books published from them.
            </Typography>
          </Stack>
        </Box> */}
        {/* <div id="bookCover"></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font35b"}>Audiobook covers</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              It’s best to start thinking about your audiobook cover early in
              the process. While the artwork accompanying your audiobook can be
              the same art that’s featured on the cover of the print and eBook
              versions, an alternative is to design an original cover for the
              audiobook. This can help with avoiding licensing fees or taking
              the marketing in a different direction if you desire.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              There are several specifications your audiobook cover must meet so
              that your work will be accepted by various distribution centres.
              We offer custom services in the creation of book covers for your
              projects. At your request, we can provide a graphic artist
              resource who can help you in reaching the perfect art cover for
              your book. We specialize in album art, graphics and animations and
              can tailor to your needs per your requisites.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Your audiobook cover is your number-one promotional tool, so it’s
              crucial to put your best foot forward. The visual you choose will
              be responsible for drawing in potential listeners—no matter how
              incredible your story is, it’s tough to sell if people aren’t
              enticed.
            </Typography>
          </Stack>
        </Box> */}
        {/* <div id="exploreACX"></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font35b"}>
              Audiobook Creation Exchange (ACX)
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              <a
                href={"https://www.acx.com/"}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant={"font18b"} color={COLORS.primary}>
                  {"Audiobook Creation Exchange (ACX) "}
                </Typography>
              </a>
              is a marketplace where authors, literary agents, publishers, and
              other right holders can connect with narrators, engineers,
              recording studios, and other producers capable of producing a
              finished audiobook. As a result, more audiobooks can be made.
            </Typography>
            <Box width="100%" sx={{ textAlign: "center" }}>
              <img src={acxScreenshot} width="90%" alt="recordingPageScreen" />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              It is brought to you by Audible.com, an Amazon.com subsidiary and
              a leading provider of audio content and entertainment. When you
              publish your audiobook on ACX, your audiobook will be made
              available on Amazon, Audible, and the Apple audiobook store.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              It’s the only place you need to go to make sure your audiobook
              gets heard by as many people as possible. You retain all of the
              audio rights, while ACX handles all of the distribution for you,
              similar to how the Kindle Direct Publishing platform works.
              <a
                href={"https://kdp.amazon.com/en_US/"}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant={"font18b"} color={COLORS.primary}>
                  {" Kindle Direct Publishing platform works"}
                </Typography>
              </a>
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              While there are a lot of steps, uploading is a user-friendly and
              self-explanatory process.
            </Typography>
          </Stack>
        </Box> */}
        {/* <div id="uploadACX"></div>
        <Box py={5}>
          <Stack>
            <Typography variant={"font30b"}>
              Here is a step-by-step guide on how to upload your audiobook to
              ACX
            </Typography>
            <Typography variant={"font18"}>
              <ul>
                <li>
                  Go to the
                  <a
                    href={"https://www.acx.com/"}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant={"font18b"} color={COLORS.primary}>
                      {" ACX website"}
                    </Typography>
                  </a>
                  .
                </li>
                <li>Login to your amazon.com account.</li>
                <li>
                  Click ‘Add your title.’ [Note: You must have a Kindle ebook
                  published.]
                </li>
                <li>
                  Search and find your book and then click on the ‘This is my
                  book’ prompt.
                </li>
                <li>
                  Click on the ‘I have this book in audio and I want to sell it’
                  prompt.
                </li>
                <li>Choose your target territory and distribution.</li>
                <li>
                  (Note: We recommend the ‘World’ rights options with 40%
                  royalties for the best results.)
                </li>
                <li>
                  Choose the language(s) you would like to sell the book in.
                </li>
                <li>
                  Agree to the ‘Audiobook license and distribution agreement’
                  terms.
                </li>
                <li>Complete the ‘About my book’ section.</li>
                <li>
                  (Note: You can duplicate the content from your Amazon page or
                  create original content.)
                </li>
                <li>Complete the proper copyright information.</li>
                <li>
                  Complete the information about the narrator, audiobook
                  publisher, and any reviews.
                </li>
                <li>Click the ‘Add audio file’ prompt.</li>
                <li>
                  Go to browse the first section of your audiobook to ensure it
                  was added.
                </li>
                <li>
                  Continue this process until your entire book is uploaded.
                </li>
                <li>
                  Don’t forget to change the chapters and section titles as you
                  go.
                </li>
                <li>Finally, upload your book cover.</li>
              </ul>
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Make sure that all of the information from your digital copy
              matches that of your audiobook. This includes several things
              ranging from the author’s name to the book cover. And it should
              all be the same as it appears on your eBook.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              ACX will not allow you to continue if there are discrepancies in
              identifying information.
            </Typography>
          </Stack>
        </Box> */}
        {/* <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>
              What are audiobook royalties on ACX?
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              When you publish your audiobook on the ACX, you will earn between
              20%-40% of their title royalties. If you work with a producer,
              then you will have a royalty share with them, and the rate that
              you receive is dependent on how your producer is compensated.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you work by yourself, you keep the whole 40%, if you split it
              with a producer, you could each earn 20%. In the end, it depends
              on how you decide to share it.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              You can catch up on more details from the ACX website or check out
              what information you want by exploring their website. You can
              catch up on more details from the ACX website or check out what
              information you want by exploring their
              <a
                href={"https://www.acx.com/help/what-s-the-deal/200497690"}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant={"font18b"} color={COLORS.primary}>
                  {" website"}
                </Typography>
              </a>
              .
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              <b>Also, a quick heads up</b> - your audiobook will not be posted
              immediately. ACX will hold your submission to confirm that all its
              standards are met and that everything is in order before it posts
              your audiobook.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              <b>
                Don’t be alarmed if you see an ACX note telling you “This title
                is: Pending audio review.”
              </b>
              It is a normal part of the waiting process and is not based on
              negative feedback from ACX. When ACX approves your book, you will
              then have the green light to sell the audio copies online.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you want a better insight on the entire process, from
              production to distribution check out
              <a
                href={"https://www.acx.com/help/authors/200484540"}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant={"font18b"} color={COLORS.primary}>
                  {" ACX author’s page"}
                </Typography>
              </a>
              .
            </Typography>
          </Stack>
        </Box> */}
        {/* <Box py={5}>
          <Stack spacing={3}>
            <Typography variant="font40b" color={COLORS.primary}>
              Let's make an audiobook
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you have made it this far and are ready to build your
              audiobook, we would encourage you to jump on the audiobook train
              as soon as possible.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you work by yourself, you keep the whole 40%, if you split it
              with a producer, you could each earn 20%. In the end, it depends
              on how you decide to share it.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Even if you have never done it before, our technology makes the
              process of creating an audiobook easier than you can imagine. A
              well-produced audiobook can help you expand your fan base and earn
              new readers. Don’t be deterred by the idea that creating an
              audiobook is outside of your wheelhouse—we promise it’s not!
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              With a little professional help (or even a little elbow grease),
              you can have a completed audiobook within weeks, and be on your
              way to boosting those book sale numbers!
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              It is an accomplishment to launch your audiobook. So from the
              entire Audifyz team, we welcome you and cannot wait to start
              working with you.
            </Typography>
          </Stack>
        </Box> */}
        <Box>
          <Typography
            variant={"font18"}
            lineHeight="30px"
            style={{ textAlign: "justify" }}
          >
            Now, it's our turn! Your audiobook files will go through final
            processing. Through your publisher, we'll submit these files and the
            audiobook cover to audiobook distribution sites like ACX for you.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default PublishBook;
