import React, { useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import ProjectsTable from "./sections/ProjectsTable";
import { useLocation } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";
import { ProjectCreateModal } from "components/modals";
import { COLORS } from "constants";
import AccessDenied from "components/common/AccessDenied";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";

function ManageProjects() {
  const search = useLocation().search;
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const searchParam = new URLSearchParams(search).get("search");
  const openModal = useStateUpdate(false);

  useEffect(() => {
    return () => {};
  }, []);

  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <ProjectCreateModal open={openModal} />
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Projects
        </Typography>
      </Grid>
      <Stack spacing={2}>
        <ProjectsTable
          {...{
            searchParam,
            openModal,
          }}
        />
      </Stack>
    </Box>
  );
}

export default ManageProjects;
