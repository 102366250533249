import { Stack, Box, Typography, Container } from "@mui/material";
import { COLORS } from "constants";
import personRecording from "assets/images/person_recording.jpg";

function TipsForRecording() {
  return (
    <Box>
      <Container maxWidth="md">
        <Box py={5}>
          <Stack spacing={3}>
            <Box display="flex" justifyContent="center">
              <img
                src={personRecording}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
            <Typography variant={"font40b"} color={COLORS.primary}>
              Here are some pointers to help you get set up for recording
              without spending a lot of money
            </Typography>
            <Typography variant={"font21"}>
              <ul>
                <li>Turn off all fans and machines.</li>
                <li>Read in a small, carpeted area.</li>
                <li>Stay a consistent distance away from the microphone.</li>
                <li>
                  Be prepared to make mistakes and record sentences over when
                  necessary.
                </li>
                <li>Read the chapter through from start to end.</li>
                <li>
                  Keep your voice at a similar level and tone across recording
                  sessions.
                </li>
                <li>Modulate your breathing and don’t hold your breath.</li>
                <li>
                  Read from a Kindle or smart device. No page-turning sounds.
                </li>
                <li>
                  Schedule sessions several days apart. Avoid sounding
                  exhausted.
                </li>
              </ul>
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              With our integrated software and your mic, you will be able to get
              a decent quality recording of your book. But keep in mind that,
              recording your audiobook is an exhausting process and it isn’t for
              everyone.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              You have to set yourself up with the proper environment and invest
              quality time for recording. If you have never used technology or
              any type of recording equipment before, there is a learning curve
              that add weeks to the audiobook production.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              For these reasons you may decide to hire someone for the first
              audiobook, learn what you can, and then try it for your next book.
            </Typography>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>
              Follow an appropriate microphone technique
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              We've all heard distracting plosives or "P-Pops" before and
              cringed at hyper-sibilant "S" sounds. Excessive bursts of air
              hitting the microphone cap cause both of these issues, and in both
              cases, it is a symptom of poor mic technique.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you notice too many plosives or harsh sibilance in your
              recordings, the simplest solution is to move off-axis from the
              microphone. Setting your mic up to the side, angled slightly
              toward your mouth, will prevent bursts of air from hitting the
              capsule directly and can result in a smoother, more natural
              podcast audio.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              In general, you want to position your mouth 4 – 6 inches away from
              the microphone for the cleanest sound, but the optimal distance
              may vary based on your microphone.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Try recording yourself talking at a consistent volume at several
              different positions, and once you do, listen to the recording to
              see which position gave you the best audio quality.
            </Typography>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>
              Grab a drink and start recording
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              So, you've written your outline, picked a place to record, set up
              your microphone, and practised your mic technique. Now it's time
              to grab a drink and start recording your first chapter.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Having a glass of water, coffee, or whiskey nearby will do wonders
              for your voice if it starts to get dry, but try to avoid
              carbonated drinks as that will increase the chances of a burp or
              hiccup making its way into your recording.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Don't worry about mistakes, stammers, or a little silence. You can
              make all the necessary edits if and when required.
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default TipsForRecording;
