import React, { Fragment, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  Tooltip,
  TextField,
} from "@mui/material";
import MergeParagraphUpwards from "assets/images/merge-upwards.png";
import MergeParagraphIcon from "assets/images/text.png";
import { MERGE_DIRECTIONS } from "constants";
import { COLORS } from "constants";
import {
  verifyRightSideButtons,
  iconButtonImage,
  borderLessTextField,
} from "components/common/Styles";
import { ThreeDots } from "react-loader-spinner";

function NextPageParagraph(props) {
  const {
    pages,
    selectedIndex,
    pageContents,
    fetchPageDetails,
    callMergePara,
    nextPageFirstParagraph,
    resetNextPageStates,
    nextPageIdToLoad,
    setConfirmModalData,
    calltoast,
  } = props;
  const classes = verifyRightSideButtons();
  const classes2 = iconButtonImage();
  const classes3 = borderLessTextField();

  useEffect(() => {
    if (nextPageFirstParagraph.state === "") {
      resetNextPageStates();
      calltoast(
        "error",
        "The paragraph was not found. Please check the next page."
      );
    }
  }, [nextPageFirstParagraph.state]);

  const renderMergeWithAboveButton = () => (
    <Tooltip title={`Merge with above paragraph`} placement="top">
      <IconButton
        color="primary"
        onClick={() => {
          setConfirmModalData(
            MergeParagraphIcon,
            "Merge paragraphs",
            `Are you sure you want to merge paragraphs?`,
            "Yes, merge paragraphs",
            "Cancel, keep paragraphs",
            async () => {
              callMergePara(
                nextPageFirstParagraph.state.paragraphId,
                pageContents.state[pageContents.state.length - 1].paragraphId,
                MERGE_DIRECTIONS.up,
                1
              );
            },
            null
          );
        }}
        className={classes.button}
      >
        <img
          src={MergeParagraphUpwards}
          className={classes2.buttonImage}
          alt="merge para"
        />
      </IconButton>
    </Tooltip>
  );

  const renderNextPageContent = () =>
    nextPageFirstParagraph.state ? (
      <Fragment>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="font15b" color={COLORS.primary}>
            {`First paragraph of page ${selectedIndex.state + 1}`}
          </Typography>
          <Typography
            variant="font14b"
            color={COLORS.primary}
            onClick={resetNextPageStates}
            sx={{
              "&:hover": { cursor: "pointer" },
            }}
          >
            Close
          </Typography>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            width: "100%",
            my: 3,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Box p={1}>
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  className={classes3.textField}
                  value={nextPageFirstParagraph.state.paragraphText}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                {renderMergeWithAboveButton()}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Fragment>
    ) : (
      <Box display="flex" justifyContent="center">
        <ThreeDots height="20" color={COLORS.primary} />
      </Box>
    );

  const renderNextPageButton = () => (
    <Button
      onClick={() => {
        nextPageIdToLoad.update(pages.state[selectedIndex.state + 1].pageId);
        fetchPageDetails(
          pages.state[selectedIndex.state + 1].pageId,
          nextPageFirstParagraph
        );
      }}
    >
      <Typography variant="font15b">
        View next page's first paragraph
      </Typography>
    </Button>
  );

  return (
    <Box
      p={1}
      sx={{
        textAlign: "center",
        border: "1px solid rgba(39, 56, 145, 0.5)",
        borderRadius: "10px",
        backgroundColor: "rgba(39, 56, 145, 0.1)",
      }}
    >
      {nextPageIdToLoad.state
        ? renderNextPageContent()
        : renderNextPageButton()}
    </Box>
  );
}

export default NextPageParagraph;
