import { apiCall } from "./AuthProvider";

const SERVICE_URL = "notification-service/";

export const getNotifications = async (searchParams) => {
  const url = `${SERVICE_URL}notifications/user${searchParams}`;
  return apiCall("get", url);
};

export const markNotificationRead = async (body) => {
  const url = `${SERVICE_URL}notifications/read`;
  return apiCall("post", url, body);
};
