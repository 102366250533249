import React from "react";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { COLORS } from "constants";
import { highlight, languages } from "prismjs/components/prism-core";
import Editor from "react-simple-code-editor";
import { scrollableSide } from "components/common/Styles";
import FormatIndentDecreaseRoundedIcon from "@mui/icons-material/FormatIndentDecreaseRounded";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import "prismjs/components/prism-clike";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css";

let formatter = require("html-formatter");

function EmailTemplateRightSection(props) {
  const {
    isHideLeft,
    isUpdateTemplate,
    emailTemplateCode,
    codeForRender,
    emailTemplateType,
    emailFromAddress,
    emailSubject,
    emailText,
    callUpdateOrCreateFunction,
    checkValidatorOnSubmit,
    validationError,
    isButtonDisabled,
    isEmptyString,
    isValidEmail,
    isUpdating,
  } = props;
  const classes = scrollableSide();

  return (
    <Grid container item xs={isHideLeft.state ? 11.5 : 9}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Box p={1}>
          <Typography variant="font15b" color={COLORS.primary}>
            {isUpdateTemplate.state
              ? "Update email template"
              : "Create email template"}
          </Typography>
        </Box>
        <Box px={2} pb={5} className={classes.emailTemplateRight}>
          <Stack spacing={2}>
            <Grid container>
              <Grid
                item
                xs={5}
                sx={{
                  p: 1,
                  borderRadius: 5,
                  border: `2px solid ${
                    validationError.state.emailTemplateCode ? "#ff0000" : "#ccc"
                  }`,
                }}
              >
                <Box
                  p={1}
                  sx={{
                    height: "60vh",
                    overflowY: "auto",
                  }}
                >
                  <Editor
                    value={emailTemplateCode.state}
                    onValueChange={(code) => {
                      validationError.update({
                        ...validationError.state,
                        emailTemplateCode: "",
                      });
                      emailTemplateCode.update(code);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          emailTemplateCode: "Code is required",
                        });
                      }
                    }}
                    highlight={(code) => highlight(code, languages.html)}
                    padding={10}
                    placeholder="<-- html code here -->"
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 12,
                      minHeight: "440px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={1}
              >
                <Stack spacing={4}>
                  <Tooltip title="Format html code">
                    <IconButton
                      size="large"
                      sx={{
                        "&:hover": {
                          backgroundColor: COLORS.primary,
                          color: COLORS.white,
                        },
                      }}
                      onClick={(e) => {
                        emailTemplateCode.update(
                          formatter.render(emailTemplateCode.state)
                        );
                      }}
                    >
                      <FormatIndentDecreaseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Render html code">
                    <IconButton
                      size="large"
                      sx={{
                        "&:hover": {
                          backgroundColor: COLORS.primary,
                          color: COLORS.white,
                        },
                      }}
                      onClick={(e) => {
                        codeForRender.update(emailTemplateCode.state);
                      }}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  p: 1,
                  borderRadius: 5,
                  border: "2px solid #ccc",
                }}
              >
                <Stack>
                  <Box
                    p={1}
                    sx={{
                      height: "60vh",
                      overflowY: "auto",
                    }}
                  >
                    {codeForRender.state && (
                      <iframe
                        title="HTML Renderer"
                        srcDoc={codeForRender.state}
                        style={{ width: "100%", height: "60vh" }}
                      />
                    )}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <TextField
              disabled={isUpdateTemplate.state}
              helperText={validationError.state.emailTemplateType}
              error={Boolean(validationError.state.emailTemplateType)}
              label={
                <Typography variant="font15">Email template type</Typography>
              }
              fullWidth
              value={emailTemplateType.state}
              onChange={(e) => {
                validationError.update({
                  ...validationError.state,
                  emailTemplateType: "",
                });
                emailTemplateType.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  validationError.update({
                    ...validationError.state,
                    emailTemplateType: "Email template type is required",
                  });
                }
              }}
            />
            <TextField
              helperText={validationError.state.emailFromAddress}
              error={Boolean(validationError.state.emailFromAddress)}
              label={
                <Typography variant="font15">Email from address</Typography>
              }
              fullWidth
              value={emailFromAddress.state}
              onChange={(e) => {
                validationError.update({
                  ...validationError.state,
                  emailFromAddress: "",
                });
                emailFromAddress.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  validationError.update({
                    ...validationError.state,
                    emailFromAddress: "Email template type is required",
                  });
                } else if (!isValidEmail(e.target.value)) {
                  validationError.update({
                    ...validationError.state,
                    emailFromAddress: "Email is not valid.",
                  });
                }
              }}
            />
            <TextField
              helperText={validationError.state.emailSubject}
              error={Boolean(validationError.state.emailSubject)}
              fullWidth
              label={<Typography variant="font15">Email subject</Typography>}
              value={emailSubject.state}
              onChange={(e) => {
                validationError.update({
                  ...validationError.state,
                  emailSubject: "",
                });
                emailSubject.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  validationError.update({
                    ...validationError.state,
                    emailSubject: "Email subject is required",
                  });
                }
              }}
            />
            <TextField
              helperText={validationError.state.emailText}
              error={Boolean(validationError.state.emailText)}
              fullWidth
              label={<Typography variant="font15">Email text</Typography>}
              multiline
              minRows={5}
              value={emailText.state}
              onChange={(e) => {
                validationError.update({
                  ...validationError.state,
                  emailText: "",
                });
                emailText.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isEmptyString(e.target.value)) {
                  validationError.update({
                    ...validationError.state,
                    emailText: "Email Text is required",
                  });
                }
              }}
            />
            <Box display="flex" justifyContent="center">
              <Button
                disabled={isButtonDisabled() || isUpdating.state}
                variant="contained"
                onClick={(e) => {
                  checkValidatorOnSubmit() && callUpdateOrCreateFunction();
                }}
              >
                <Typography variant="font15b">
                  {isUpdateTemplate.state
                    ? "Update template"
                    : "Create template"}
                </Typography>
                {isUpdating.state && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      marginLeft: "-10px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Paper>
    </Grid>
  );
}

export default EmailTemplateRightSection;
