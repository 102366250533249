import React from "react";
import { Typography, Grid, Divider } from "@mui/material";

function Email({ profileData }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="font14">{profileData.state?.email}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default Email;
