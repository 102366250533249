export function isEmptyString(data) {
  data = data.toString();
  return !data.trim().length;
}

export function isValidPhoneNumber(phoneNumber) {
  const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
  return regex.test(phoneNumber);
}

export function isValidPassword(password) {
  return password.length >= 8;
}

export function isValidEmail(email) {
  return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
}

export function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
