import { BASE_PATH } from "constants";
import { BASE_IMAGE_PATH } from "constants";

export const getImageUrl = (encodedPath) => {
  const imageUrl = `${BASE_IMAGE_PATH}${decodeURIComponent(encodedPath)}`;
  return imageUrl;
};

export const getAudioUrl = (encodedPath) => {
  const audioUrl = `${BASE_IMAGE_PATH}${decodeURIComponent(encodedPath)}`;
  return audioUrl;
};

// export const getAudioUrl = (encodedPath) => {
//   const audioUrl = `${BASE_PATH}book-service/books/audio/${encodedPath}`;
//   console.log(audioUrl);
//   return audioUrl;
// };

export const getProfilePicUrl = (encodedPath) => {
  const imageUrl = `${BASE_PATH}user-service/image/${encodedPath}`;
  return imageUrl;
};
