import React, { Fragment, useEffect } from "react";

import LandingSection from "./sections/LandingSection";
import KnowMoreSection from "./sections/KnowMoreSection";
import PlatformSection from "./sections/PlatformSection";
import QuestionSection from "./sections/QuestionSection";
import FooterSection from "./sections/FooterSection";
import { useStateUpdate } from "UseStateUpdate";
import { useLocation } from "react-router-dom";

function HomePage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const divParam = searchParams.get("div");
  const divToScroll = useStateUpdate(null);

  useEffect(() => {
    const element = document.getElementById(divParam);
    if (element) {
      element.scrollIntoView({
        block: "center",
      });
    }
  }, [divParam]);

  useEffect(() => {
    if (divToScroll.state) {
      const element = document.getElementById(divToScroll.state);
      if (element) {
        element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    }
  }, [divToScroll.state]);

  return (
    <Fragment>
      <div id="home">
        <LandingSection />
      </div>
      <KnowMoreSection />
      <PlatformSection />
      <div id="contactUs">
        <QuestionSection />
      </div>
      <FooterSection />
    </Fragment>
  );
}

export default HomePage;
