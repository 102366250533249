import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import Correct from "assets/images/correct.png";
import AddParagraph from "assets/images/add-file.png";
import DeleteParagraph from "assets/images/file-delete.png";
import {
  iconButtonImage,
  verifyRightSideButtons,
  borderLessTextField,
} from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import DeleteParagraphIcon from "assets/images/bin.png";
import SaveParagraphIcon from "assets/images/save.png";
import SaveParagraph from "assets/images/save-para.png";
import { RecordingPageContext } from "context/context";

function RightScrollableParagraphView(props) {
  const { isDisabled } = props;
  const {
    pageContents,
    selectedParagraphIndex,
    setConfirmModalData,
    callDeletePara,
    isParagraphActionsEnabled,
    callCreatePara,
    callUpdateParagraph,
    openConfirmModal,
    isEditing,
  } = useContext(RecordingPageContext);
  const classes = iconButtonImage();
  const classes2 = verifyRightSideButtons();
  const classes3 = borderLessTextField();
  const pageContentsToEdit = useStateUpdate(null);
  const onEditOrderIndex = useStateUpdate(null);
  const currentEditingParagraph = useStateUpdate("");

  useEffect(() => {
    onEditOrderIndex.update(null);
    pageContents.state.length > 0
      ? pageContentsToEdit.update(pageContents.state)
      : pageContentsToEdit.update([
          {
            orderIndex: 100,
            paragraphText: "",
            newPara: true,
          },
        ]);
  }, [pageContents.state]);

  useEffect(() => {
    setTimeout(() => {
      scrollToDiv(selectedParagraphIndex.state);
    }, 200);
  }, [selectedParagraphIndex.state]);

  const addNewParagraph = (indexIs) => {
    let newPageContent = [];
    pageContentsToEdit.state.forEach((paragraph, index) => {
      let newParagraph = paragraph;
      newPageContent.push(newParagraph);
      if (indexIs === index) {
        let newIndex;

        if (pageContentsToEdit.state.length - 1 === index) {
          newIndex = Math.round(paragraph.orderIndex + 100);
        } else {
          newIndex = Math.round(
            (paragraph.orderIndex +
              pageContentsToEdit.state[index + 1].orderIndex) /
              2
          );
        }

        newParagraph = {
          orderIndex: newIndex,
          paragraphText: "",
          newPara: true,
          isRecorded: false,
        };
        newPageContent.push(newParagraph);
      }
    });
    pageContentsToEdit.update(newPageContent);
  };

  const handleSaveCancel = () => {
    pageContentsToEdit.update(pageContents.state);
    onEditOrderIndex.update(null);
    openConfirmModal.update(false);
  };

  const handleParagraphChange = (newParagraphText, orderIndex) => {
    let newPageContent = [];
    pageContentsToEdit.state.forEach((paragraph) => {
      let newParagraph = paragraph;
      if (paragraph.orderIndex === orderIndex) {
        newParagraph = {
          ...paragraph,
          paragraphText: newParagraphText,
        };
      }
      newPageContent.push(newParagraph);
    });
    currentEditingParagraph.update(newParagraphText);
    pageContentsToEdit.update(newPageContent);
  };

  const scrollToDiv = (id) => {
    const targetDiv = document.getElementById(`right${id}`);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Fragment>
      {pageContentsToEdit.state?.map((content, index) => (
        <Paper
          variant="outlined"
          sx={{
            width: "100%",
            my: 3,
            cursor: isDisabled && "no-drop",
            border:
              pageContentsToEdit.state[selectedParagraphIndex.state - 1]
                .paragraphId === content.paragraphId
                ? "2px solid #273891"
                : "1px solid #ccc",
            boxShadow:
              pageContentsToEdit.state[selectedParagraphIndex.state - 1]
                .paragraphId === content.paragraphId
                ? "0px 0px 10px rgba(39, 56, 145, 1)"
                : "0px 0px 10px rgba(0, 0, 0, 0.3)",
          }}
          key={content.orderIndex}
          id={`right${index + 1}`}
          onClick={(e) => {
            !content.newPara &&
              !isDisabled &&
              selectedParagraphIndex.update(index + 1);
          }}
        >
          <Box p={0.5}>
            <Grid container spacing={1}>
              <Grid item xs={12} container justifyContent="flex-end">
                {content.isRecorded && (
                  <img
                    src={Correct}
                    className={classes.buttonImage}
                    alt="Recorded"
                  />
                )}
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <TextField
                  fullWidth
                  multiline
                  className={classes3.textField}
                  value={content.paragraphText}
                  onChange={(e) => {
                    e.preventDefault();
                    onEditOrderIndex.update(content.orderIndex);
                    handleParagraphChange(e.target.value, content.orderIndex);
                  }}
                  onClick={() => {
                    isEditing.update(true);
                  }}
                  onBlur={() => {
                    isEditing.update(false);
                    if (content.orderIndex === onEditOrderIndex.state) {
                      setConfirmModalData(
                        SaveParagraphIcon,
                        "Save paragraph",
                        "Are you sure you want to save the changes?",
                        "Yes, save paragraph",
                        "Cancel, discard changes",
                        () => {
                          content.newPara
                            ? callCreatePara({
                                orderIndex: content.orderIndex,
                                paragraphText: currentEditingParagraph.state,
                              })
                            : callUpdateParagraph(content.paragraphId, {
                                paragraphText: currentEditingParagraph.state,
                              });
                        },
                        () => {
                          handleSaveCancel();
                        }
                      );
                    }
                  }}
                />
              </Grid>
              {onEditOrderIndex.state === content.orderIndex ? (
                <Tooltip title="Save changes" placement="top">
                  <IconButton
                    disabled={isDisabled}
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      setConfirmModalData(
                        SaveParagraphIcon,
                        "Save paragraph",
                        "Are you sure you want to save the changes?",
                        "Yes, save paragraph",
                        "Cancel, discard changes",
                        () => {
                          callCreatePara({
                            orderIndex: content.orderIndex,
                            paragraphText: currentEditingParagraph.state,
                          });
                        },
                        () => {
                          handleSaveCancel();
                        }
                      );
                    }}
                  >
                    <img
                      src={SaveParagraph}
                      className={classes.buttonImage}
                      alt="save"
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                isParagraphActionsEnabled.state &&
                !content.newPara && (
                  <Grid item xs={12} container spacing={5}>
                    <Grid item xs={2}>
                      <Tooltip title="Add paragraph" placement="top">
                        <IconButton
                          disabled={isDisabled}
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            addNewParagraph(index);
                          }}
                          className={classes2.button}
                        >
                          <img
                            src={AddParagraph}
                            className={classes.buttonImage}
                            alt="AddParagraph"
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Delete paragraph" placement="top">
                        <IconButton
                          disabled={isDisabled}
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmModalData(
                              DeleteParagraphIcon,
                              "Delete paragraph......",
                              "Are you sure you want to delete this paragraph?",
                              "Yes, delete paragraph",
                              "Cancel, keep paragraph",
                              () => {
                                callDeletePara(content.paragraphId);
                              },
                              null
                            );
                          }}
                          className={classes2.button}
                        >
                          <img
                            src={DeleteParagraph}
                            className={classes.buttonImage}
                            alt="Delete"
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Paper>
      ))}
    </Fragment>
  );
}

export default RightScrollableParagraphView;
