import React from "react";
import { Box, Grid, Modal, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 850 },
  height: { xs: "auto", sm: 550 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: { xs: 2, sm: 4 },
  borderRadius: 2,
};

function ProcessGuidelinesModal({ open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Grid container justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textAlign: "center",
            fontWeight: "bold",
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
          }}
        >
          Process Guidelines
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", mb: 1 }}
          >
            📥 Download or 🎵 Play
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            The audio engineer has completed optimizing your recordings. You can
            now find all your recordings, which have been split into chapters.
            <br />
            <br />
            You have the option to either download and listen to the audio files
            or stream them using the play button.
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", mb: 1 }}
          >
            ✅ Accept or ❌ Reject
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            At this stage, you need to either accept or reject the optimized
            audio for each chapter to move forward.
            <br />
            <br />
            If you accept all chapters, they will be sent to the
            MyAudiobookRecorder production team for distribution across our
            channels. However, if you reject any chapter, it will return to the
            recording stage, and you'll be able to re-record the necessary
            sections.
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", mb: 1 }}
          >
            💬 Need Help?
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            If you need further assistance, feel free to message us in the
            thread, and our customer support team will be happy to help.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

export default ProcessGuidelinesModal;
