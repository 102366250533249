import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import MergeParagraphUpwards from "assets/images/merge-upwards.png";
import MergeParagraphDownwardsLineBreak from "assets/images/merge-downwards-linebreak.png";
import { MERGE_DIRECTIONS } from "constants";
import {
  verifyRightSideButtons,
  iconButtonImage,
} from "components/common/Styles";

function MergeUpButton(props) {
  const {
    index,
    getPrevPageLastPara,
    pages,
    selectedIndex,
    pageContentsToEdit,
    callMergePara,
    setConfirmModalData,
    content,
    calltoast,
    openConfirmModal,
    backDropOpen,
    lineBreak,
    fetchBookDetails,
    callUpdatePageStatus,
  } = props;
  const classes = verifyRightSideButtons();
  const classes2 = iconButtonImage();

  const handleMergeParagraph = async (content, index) => {
    const getPrevParaId = async () => {
      if (index === 0 && selectedIndex.state > 0) {
        return await getPrevPageLastPara(
          pages.state[selectedIndex.state - 1]?.pageId
        );
      }
      return pageContentsToEdit.state[index - 1]?.paragraphId;
    };

    const mergeWithParagraphId = await getPrevParaId();

    if (mergeWithParagraphId) {
      // Check if this is the first paragraph on the current page
      const isFirstParagraph = index === 0;
      const prevPageIndex = selectedIndex.state - 1;

      if (isFirstParagraph && prevPageIndex >= 0) {
        const prevPage = pages.state[prevPageIndex];

        if (prevPage?.isVerified) {
          try {
            console.log(`Unverifying previous page: ${prevPage.pageId}`);
            await callUpdatePageStatus(prevPage.pageId, { isVerified: false });
            console.log("Previous page successfully unverified.");
            await fetchBookDetails(); // Refresh UI after unverifying
          } catch (error) {
            console.error("Error unverifying previous page:", error);
            calltoast("error", "Failed to unverify the previous page.");
            return; // Stop the merge if unverifying fails
          }
        }
      }

      // Proceed with merging paragraphs
      const mergeDirection = MERGE_DIRECTIONS.up;
      callMergePara(
        content.paragraphId,
        mergeWithParagraphId,
        mergeDirection,
        lineBreak
      );
    } else {
      backDropOpen.update(false);
      openConfirmModal.update(false);
      calltoast(
        "error",
        "The paragraph was not found. Please check the previous page."
      );
    }
  };

  // const handleMergeClick = (content, index) => {
  //   setConfirmModalData(
  //     MergeParagraphIcon,
  //     "Merge paragraphs",
  //     `Are you sure you want to merge paragraphs?`,
  //     "Yes, merge paragraphs",
  //     "Cancel, keep paragraphs",
  //     async () => {
  //       await handleMergeParagraph(content, index);
  //     },
  //     null
  //   );
  // };

  return (
    <Tooltip
      title={`${
        lineBreak
          ? "Merge with previous paragraph with line break"
          : "Merge with previous paragraph"
      }`}
      placement="top"
    >
      <IconButton
        color="primary"
        onClick={() => {
          backDropOpen.update(true);
          handleMergeParagraph(content, index);
        }}
        className={classes.button}
      >
        <img
          src={
            lineBreak ? MergeParagraphDownwardsLineBreak : MergeParagraphUpwards
          }
          className={classes2.buttonImage}
          alt="merge para"
        />
      </IconButton>
    </Tooltip>
  );
}

export default MergeUpButton;
