import { Stack, Box, Typography, Container, Button } from "@mui/material";
import { COLORS } from "constants";
import workspaceRoom from "assets/images/workspace-room.jpg";
import micRec from "assets/images/micREC.jpg";
import headPhone from "assets/images/headPhone.jpg";
import laptop from "assets/images/laptop.jpg";
import popupfilter from "assets/images/popupfilter.jpg";
import chrome from "assets/images/chrome.png";
import adapter from "assets/images/adapter.jpg";
import usbsetup from "assets/images/usbsetup.png";
import { primaryButtonHover } from "components/common/Styles";
import { useNavigate } from "react-router-dom";
import SortIcon from "@mui/icons-material/Sort";

function SetupRecording() {
  let navigate = useNavigate();

  const renderListItem = (link, label) => {
    return (
      <li style={{ marginBottom: "10px" }}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Typography variant={"font18b"} color={COLORS.primary}>
            {label}
          </Typography>
        </a>
      </li>
    );
  };

  return (
    <Box>
      <Container maxWidth="md">
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font40b"} color={COLORS.primary}>
              Step 1: Set up your recording equipment and software
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Before we get to the recording part, there are some basic
              requirements to be met. Finding a suitable place to set up your
              tools, which includes your compuxter, mic, headphones, etc., is
              necessary as they cannot be hindrances to the whole process. The
              more flawless the integration, the better the audio output.
            </Typography>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack>
            <Typography variant={"font35b"}>Basic things needed</Typography>
            <Typography variant={"font21"}>
              <ul>
                <li>A suitable room</li>
                <li>Mic, Pop filter and Headphones</li>
                <li>Laptop/Desktop</li>
                <li>Integration</li>
              </ul>
            </Typography>
          </Stack>
        </Box>
        <div id={"room"}></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>A suitable room</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Finding a room where there is no background noise is important. We
              start with having a sound-quality check to identify whether your
              surrounding is a permitted environment to record in. We might not
              be able to hear some minor disturbances but your microphone might
              pick it up. The background noise can also be dealt with
              noise-cancelling headphones and mics, which prove to be effective.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              The room needs to be quiet. An isolated padded room or a recording
              box are some good places to have access to. Sensitive sounds from
              floor creaks, pets or even neighbours need to be looked out for as
              they can present themselves as noise. If it is difficult to find a
              place for silent recording, then hiring a producer would be the
              next best-recommended option.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img src={workspaceRoom} width="50%" alt="next" />
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font21b"}>Pick a place to record</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              While you might enjoy the echo you get singing in the shower,
              recording in small spaces with hard, flat surfaces will almost
              always mean a more reverberant- sounding output. That is not what
              you want for your audio-book. If possible, record in a quiet and
              large room with plenty of space around you. If a small space is
              all you have then try to find one with few reflective surfaces or
              lots of material that can absorb the sound: furniture, carpeting
              or even a closet full of clothes.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you manage to get a crisp recording from the start, you are
              much closer to a polished recording, and clean recorded audio will
              make the technical aspects of editing and mixing much more
              straightforward.
            </Typography>
            <Box
              sx={{
                border: `3px solid ${COLORS.primary}`,
                padding: "16px",
                borderRadius: "4px",
              }}
            >
              <Stack direction="row" spacing={1}>
                <SortIcon />
                <a
                  href={"/tips-for-recording-an-audiobook"}
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant={"font18"} color={COLORS.primary}>
                    Useful tips for recording your audiobook
                  </Typography>
                </a>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <div id={"mic"}></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>
              Mic, pop filter and headphones
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              The Usage of professional mics can reduce sound quality issues to
              a great extent. We have tested a variety of mics and can easily
              recommend the Blue yeti microphone which meets all our
              specifications. If you are just starting, this is a good option.
              You can always go for the high-end stuff but remember that as the
              microphones get better the increase in quality tends to get
              smaller. There is no need to break your wallet.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={micRec}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Pop filter, pop shield, or a pop screen is a noise protection
              filter for microphones, typically used in a recording studio. It
              serves to reduce or eliminate popping sounds caused by the
              mechanical impact of fast-moving air particles on the microphone
              during the recording sessions.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={popupfilter}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Good headphones that are attached your mic, serve as the best
              studio monitors. We can avoid retakes based on the feedback that
              you receive and then rectify to put out an improved recording.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={headPhone}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font26b"}>Buying guide</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If you are a voice artist, podcaster or music recording talent,
              you may already have access to the necessary equipment for
              recording your audiobook. Here are some of our suggestions for
              people who might not be in the mentioned category.
            </Typography>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font21b"}>Mic</Typography>
            <Box sx={{ backgroundColor: "#f5f8fa", borderRadius: 2 }} p={3}>
              <ul>
                {renderListItem(
                  "https://a.co/d/dZTp0sF",
                  "Audio-Technica ATR2100x-USB Cardioid Dynamic Microphone"
                )}
                {renderListItem(
                  "https://www.amazon.com/s?k=rode+procaster+broadcast+dynamic+vocal+microphone&tag=cuelinkss8830-21",
                  "Rode procaster broadcast dynamic vocal microphone"
                )}
                {renderListItem(
                  "https://a.co/d/9qoAP5m",
                  "Blue Microphones Yeti USB Microphone for Recording"
                )}
                {renderListItem(
                  "https://www.amazon.com/s?k=Audio-Technica+AT2035&tag=cuelinkss8830-21",
                  "Audio-Technica AT2035"
                )}
                {renderListItem(
                  "https://a.co/d/830R02G",
                  "Shure SM7B Cardioid Vocal Dynamic Microphone"
                )}
              </ul>
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font21b"}>Pop filter</Typography>
            <Box sx={{ backgroundColor: "#f5f8fa", borderRadius: 2 }} p={3}>
              <ul>
                {renderListItem(
                  "https://a.co/d/f2OE31B",
                  "WindTech PopGuard 2000"
                )}
                {renderListItem(
                  "https://a.co/d/7JbIW3m",
                  "Avantone PS-1 PRO-SHIELD Studio Pop Filter"
                )}
                {renderListItem(
                  "https://a.co/d/f0NEkx5",
                  "Nady MPF-6 6-Inch Clamp On Microphone Pop Filter"
                )}
                {renderListItem(
                  "https://a.co/d/29sWisH",
                  "Stedman Proscreen XL Pop Filter"
                )}
                {renderListItem(
                  "https://a.co/d/5Iecb7a",
                  "On-Stage ASFSS6-GB Dual Screen Microphone Pop Filter"
                )}
              </ul>
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font21b"}>Headphones</Typography>
            <Box sx={{ backgroundColor: "#f5f8fa", borderRadius: 2 }} p={3}>
              <ul>
                {renderListItem(
                  "https://a.co/d/bZEqOqY",
                  "Audio-Technica ATH-M50x Professional Studio Monitor Headphones"
                )}
                {renderListItem(
                  "https://a.co/d/htkRk5i",
                  "Sony WH-1000XM3 Industry Leading Wireless Headphones"
                )}
                {renderListItem(
                  "https://a.co/d/47DOmTz",
                  "Beyerdynamic Amiron Wireless Headphones "
                )}
                {renderListItem(
                  "https://a.co/d/0lWfqoS",
                  "Grado Prestige Series SR225e Headphones"
                )}
                {renderListItem(
                  "https://a.co/d/92j8XzO",
                  "Sennheiser Over Ear HD 820 Headphones"
                )}
              </ul>
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>Laptop/Desktop</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Any laptop, PC or workstation desktop with internet connectivity
              will do. The support for USB devices is the only requirement for
              these computers.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={laptop}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
          </Stack>
        </Box>
        <div id={"integration"}></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>Integration</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Sometimes, USB adapters need to be purchased to get set-up with
              the mics. The ideal thing is to make sure that the primary mic is
              the only source for audio input in the recording.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={adapter}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font21b"}>Set up your recording</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              The first thing is to have the microphone ready. Connect it to
              your computer directly if it is a USB mic and if you have a
              pre-amp then use your XLR cable to connect each other and then
              plug into the USB port. To start with something simple, you can
              always go with USB microphones. Always make sure that your
              plugged-in microphone is discoverable as the default audio input
              on your computer.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={usbsetup}
                width="50%"
                style={{
                  border: "8px solid #FFF",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="next"
              />
            </Box>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font21b"}>Browser</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              We recommend our clients to use Google Chrome for the best
              experience from My Audio Book Recorder.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img src={chrome} width="10%" alt="next" />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              When you record for the first time through our platform, you will
              get a popup on your browser asking to grant permission to record.
              And sometimes you need to go for the secure lock icon on the top
              left corner of the browser. Then, click the allow button to
              proceed.
            </Typography>
          </Stack>
        </Box>
        <div id={"sampleRecording"}></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font35b"}>Make a sample recording</Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Now let us test your microphone on My Audio Book Recorder.
              Navigate to the sample recording page using the record button or
              the URL. The reason we do this is to determine the quality of the
              environment you are recording in and to check the sound signals
              from the input channel (mic).
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Once you are ready and the room is quiet, click silence. To
              proceed click the microphone after you have permitted access to
              the mic. The noise levels in the surrounding environment get
              checked and a result pops up. It gives feedback based on a
              successful or a failed environment to record in.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Click the back arrow and now choose sound. Click the mic button
              again to start recording your paragraph. You can check the result
              and assess what needs to be rectified.
            </Typography>
            <Box
              width="100%"
              sx={{ textAlign: "center", backgroundColor: "#ecf1f3" }}
              p={7}
            >
              <Stack spacing={3} alignItems="center">
                <Typography
                  variant={"font18"}
                  lineHeight="30px"
                  style={{ textAlign: "justify" }}
                >
                  Click the record button to record a sample audio.
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  sx={primaryButtonHover}
                  onClick={() => {
                    navigate("/samplerecording");
                  }}
                >
                  <Typography variant="font15b" mx={4}>
                    Record
                  </Typography>
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default SetupRecording;
