import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import VerifyPageLeftScrollable from "./sections/VerifyPageLeftScrollable";
import VerifyPageRightScrollable from "./sections/VerifyPageRightScrollable";
import VerifyPageFooter from "./sections/VerifyPageFooter";
import { PageViewModal, ConfirmModal, WarningModal } from "components/modals";
import { scrollableSide } from "components/common/Styles";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import {
  EXTRA_PAGES_INDEX as ExtraPagesIndex,
  MOBILE_MAX_WIDTH,
} from "constants/otherConstant";
import { checkBookStatus } from "utils/Checkbookstatus";
import VerifyPageGuideLine from "./sections/VerifyPageGuideLine";
import AccessDenied from "components/common/AccessDenied";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { COLORS } from "constants";
import { primaryButtonHover } from "components/common/Styles";
import { verifyAllPages } from "service/PageService";
import { VerifyPageContext } from "context/context";
import BlankPagesModal from "components/modals/BlankPagesModal";
const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
function VerifyBook({ isToolbarHidden }) {
  const {
    openConfirmModal,
    openWarningModal,
    warningModalMessage,
    navigateTo,
    bookDetails,
    pages,
    totalPages,
    selectedIndex,
    isParagraphActionsEnabled,
    bookId,
    backDropOpen,
    openPageViewModal,
    isFetching,
    openGuideLineModal,
    confirmModalData,
    pageUrl,
    isFilterOnlyUnVerified,
    isVerifyCompleted,
    fetchBookDetails,
    callFetchPageDetails,
  } = useContext(VerifyPageContext);
  const classes = scrollableSide();
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const [openBlankPagesModal, setOpenBlankPagesModal] = useState(false);
  const [blankPages, setBlankPages] = useState([]);
  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      !isAuthorOrEngineer && fetchBookDetails();
    }
  }, []);
  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        BookStatusCodes.bookUploaded,
        bookDetails.state,
        bookDetails.state.pages.length,
        "Currently, this book is not in the verifying stage."
      );
      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);
      if (!bookStatus.isOpenModal) {
        pages.update(bookDetails.state.pages);
        totalPages.update(bookDetails.state.pages.length - 1);
      }
    }
  }, [bookDetails.state]);
  useEffect(() => {
    if (pages.state) {
      !selectedIndex.state && selectedIndex.update(1);
      const verifyCompleted = pages.state
        .slice(1)
        .every((page) => page.isVerified);
      isVerifyCompleted.update(verifyCompleted);
    }
  }, [pages.state]);
  const isActionsEnabled = (index) => {
    return !(
      index === ExtraPagesIndex.sampleRecord ||
      index === ExtraPagesIndex.openingCredits
    );
  };
  useEffect(() => {
    if (selectedIndex.state) {
      isParagraphActionsEnabled.update(isActionsEnabled(selectedIndex.state));
      callFetchPageDetails();
    }
  }, [selectedIndex.state]);
  const handleVerifyAllButton = async () => {
    if (!pages.state || !Array.isArray(pages.state)) {
      console.error("Pages state is undefined or not an array.");
      return;
    }
    const blankPageIds = pages.state
      .filter((page) => page?.paragraphCount === 0)
      .map((page) => page.pageId);
    console.log("blankPageIds", blankPageIds);
    if (blankPageIds.length > 0) {
      setBlankPages(blankPageIds);
      setOpenBlankPagesModal(true);
    } else {
      callVerifyAllPages();
    }
  };
  // const callVerifyAllPages = async () => { // const pagesData = pages.state; // Get the current pages array // const blankPagesList = pagesData.filter( // (page) => page.paragraphCount === 0 // );
  // if (blankPagesList.length > 0) { // setBlankPages(blankPagesList); // setOpenBlankPagesModal(true); // } else { // const result = await verifyAllPages(bookId, { isVerified: true }); // if (result.status === 200) fetchBookDetails(); // } // };
  // const handleDeleteBlankPages = async () => { // for (const page of blankPages) { // //await deletePage(page.pageId); //Api // } // setOpenBlankPagesModal(false); // fetchBookDetails(); // };
  const callVerifyAllPages = async () => {
    const result = await verifyAllPages(bookId, { isVerified: true });
    result.status === 200 && fetchBookDetails();
  };
  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Fragment>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropOpen.state}
      >
        {" "}
        <CircularProgress color="inherit" />{" "}
      </Backdrop>{" "}
      <PageViewModal open={openPageViewModal} pageUrl={pageUrl.state} />{" "}
      <ConfirmModal {...{ openConfirmModal, confirmModalData }} />{" "}
      <WarningModal
        {...{ openWarningModal, warningModalMessage, navigateTo }}
      />{" "}
      <VerifyPageGuideLine {...{ openGuideLineModal }} />{" "}
      <BlankPagesModal
        open={openBlankPagesModal}
        blankPages={blankPages}
        pages={pages}
        callVerifyAllPages={callVerifyAllPages}
        fetchBookDetails={fetchBookDetails}
        onClose={() => setOpenBlankPagesModal(false)}
      />{" "}
      <Box style={{ marginTop: "10px" }}>
        {" "}
        <Box px={15}>
          {" "}
          <Grid container spacing={15}>
            {" "}
            <Grid item xs={4}>
              {" "}
              {!isFetching.state && (
                <Stack>
                  {" "}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    style={{ border: "2px solid #ccc", borderRadius: 10 }}
                  >
                    {" "}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isFilterOnlyUnVerified.state}
                          color="primary"
                          onChange={(e) =>
                            isFilterOnlyUnVerified.update(e.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography variant="font15b" color={COLORS.primary}>
                          {" "}
                          View unverified{" "}
                        </Typography>
                      }
                      labelPlacement="start"
                    />{" "}
                    <Button
                      disabled={isVerifyCompleted.state}
                      variant="outlined"
                      sx={primaryButtonHover}
                      size="small"
                      onClick={handleVerifyAllButton}
                    >
                      {" "}
                      <Typography variant="font15b">Verify all</Typography>{" "}
                    </Button>{" "}
                  </Box>{" "}
                  <Grid
                    className={classes.verifying}
                    sx={{ maxHeight: isToolbarHidden.state ? "86vh" : "70vh" }}
                    py={1}
                    pl={5}
                    pr={2}
                  >
                    {" "}
                    <VerifyPageLeftScrollable />{" "}
                  </Grid>{" "}
                </Stack>
              )}{" "}
            </Grid>{" "}
            <Grid
              item
              xs={8}
              className={classes.verifying}
              sx={{ maxHeight: isToolbarHidden.state ? "90vh" : "77vh" }}
              p={1}
              pr={2}
            >
              {" "}
              {!isFetching.state && <VerifyPageRightScrollable />}{" "}
            </Grid>{" "}
          </Grid>{" "}
        </Box>{" "}
        {!isFetching.state && <VerifyPageFooter />}{" "}
      </Box>{" "}
    </Fragment>
  );
}
export default VerifyBook;
