import React, { Fragment, useEffect } from "react";
import { useStateUpdate } from "UseStateUpdate";
import {
  Button,
  Stack,
  Typography,
  Grid,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { iconButton } from "components/common/Styles";

import { formatPhoneNumber } from "utils/FormatPhoneNumber";
import { EditOutlined } from "@material-ui/icons";
import { isValidPhoneNumber } from "utils/FormValidator";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { updateProfile } from "service/UserService";
import { reFormatPhoneNumber } from "utils/FormatPhoneNumber";

function PhoneNumber({ profileData, fetchProfileData, calltoast }) {
  const phoneNumber = useStateUpdate("");
  const changePhoneNumber = useStateUpdate("");
  const isEditPhoneNumber = useStateUpdate(false);

  const phoneError = useStateUpdate("");

  useEffect(() => {
    phoneNumber.update(profileData.state?.phone ?? "");
  }, [profileData.state]);

  useEffect(() => {
    changePhoneNumber.update(formatPhoneNumber(phoneNumber.state));
  }, [phoneNumber.state]);

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newPhoneError = "";
    if (!isValidPhoneNumber(changePhoneNumber.state)) {
      newPhoneError = "Phone Number is not valid";
      isValidated = false;
    }
    phoneError.update(newPhoneError);
    return isValidated;
  }

  async function updatePersonalDetails() {
    const body = {
      phone: reFormatPhoneNumber(changePhoneNumber.state),
    };
    const result = await updateProfile(body);
    if (result.status === 200) {
      fetchProfileData();
      isEditPhoneNumber.update(false);
      calltoast("success", "Number has been changed");
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
  }

  return (
    <Grid container>
      {isEditPhoneNumber.state ? (
        <Fragment>
          <Grid item xs={10}>
            <TextField
              id="sms-number"
              error={phoneError.state === "" ? false : true}
              helperText={phoneError.state}
              label={<Typography variant="font12">Phone Number</Typography>}
              inputProps={{ maxLength: 16 }}
              variant="standard"
              color="primary"
              fullWidth
              value={changePhoneNumber.state}
              onChange={(e) => {
                phoneError.update("");
                changePhoneNumber.update(formatPhoneNumber(e.target.value));
              }}
              onBlur={(e) => {
                !isValidPhoneNumber(e.target.value) &&
                  phoneError.update("Phone Number is not valid");
              }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack direction="row" spacing={2}>
              <IconButton
                color="primary"
                size="small"
                onClick={(e) => {
                  checkValidatorOnSubmit() && updatePersonalDetails();
                }}
                sx={iconButton.border}
              >
                <DoneIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={() => isEditPhoneNumber.update(false)}
                sx={iconButton.border}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid item xs={10}>
            <Typography variant="font14">
              {formatPhoneNumber(phoneNumber.state)}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={2}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {phoneNumber.state === "" ? (
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  isEditPhoneNumber.update(true);
                }}
              >
                <Typography variant="font11">Add number</Typography>
              </Button>
            ) : (
              <IconButton
                size="small"
                onClick={() => {
                  isEditPhoneNumber.update(true);
                }}
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default PhoneNumber;
