import React, { useEffect } from "react";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  Typography,
  Box,
  Paper,
  Grid,
  FormHelperText,
  IconButton,
  InputAdornment,
  Input,
  InputLabel,
  FormControl,
  CircularProgress,
  Alert,
} from "@mui/material";
import { isValidPassword } from "utils/FormValidator";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetPassword, verifyEmailToken } from "service/AuthService";
import { useAuth, logout } from "service/AuthProvider";

function ForgotPasswordReset() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const isLoader = useStateUpdate(false);
  const password = useStateUpdate("");
  const showPassword = useStateUpdate(false);
  const reEnterPassword = useStateUpdate("");
  const showReEnterPassword = useStateUpdate(false);
  const passwordHelperText = useStateUpdate(
    "Password must contain minimum 8 characters"
  );
  const isResetPassword = useStateUpdate(false);
  const isErrorOccured = useStateUpdate(false);
  const isTokenValid = useStateUpdate(true);
  const validationError = useStateUpdate({
    password: false,
    reEnterPassword: "",
  });
  const isNewAccount = useStateUpdate(
    pathname.indexOf("/account/new-account-verification") > -1
  );

  const isAuthenticated = useAuth();

  useEffect(() => {
    if (token) {
      isAuthenticated && logOutUser();
      callVerifyEmailToken();
    } else {
      navigate("/login");
    }
  }, []);

  const logOutUser = async () => {
    logout();
    window.location.reload();
  };

  function checkValidatorOnSubmit() {
    let isValidated = true;
    const newValidationError = {
      password: !isValidPassword(password.state),
      reEnterPassword:
        password.state !== reEnterPassword.state
          ? "Password does not match"
          : "",
    };
    isValidated = !Object.values(newValidationError).some((error) => !!error);
    validationError.update(newValidationError);
    return isValidated;
  }

  async function callVerifyEmailToken() {
    isLoader.update(true);

    const result = await verifyEmailToken(token);
    if (result.status === 200) {
      isTokenValid.update(true);
    } else {
      isTokenValid.update(false);
    }

    isLoader.update(false);
  }

  async function callResetPassword() {
    isLoader.update(true);

    const result = await resetPassword(token, password.state);
    if (result.status === 200) {
      isResetPassword.update(true);
    } else {
      isErrorOccured.update(true);
    }

    isLoader.update(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper>
        <Box mt={10} py={5} px={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={1}
          >
            <Typography variant="font20b">
              {isNewAccount.state ? "Create New Password" : "Reset Password"}
            </Typography>
          </Grid>

          <Grid item xs={12} mb={3}>
            <FormControl
              disabled={isResetPassword.state || !isTokenValid.state}
              variant="standard"
              color="primary"
              fullWidth
            >
              <InputLabel error={!!validationError.state.password}>
                <Typography variant="font14">New Password</Typography>
              </InputLabel>
              <Input
                id="password"
                error={validationError.state.password ? true : false}
                type={showPassword.state ? "text" : "password"}
                value={password.state}
                onChange={(e) => {
                  isErrorOccured.update(false);
                  validationError.update({
                    ...validationError.state,
                    password: false,
                  });
                  password.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => showPassword.update(!showPassword.state)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword.state ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={validationError.state.password ? true : false}
                id="standard-weight-helper-text"
              >
                {passwordHelperText.state}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} mb={3}>
            <FormControl
              disabled={isResetPassword.state || !isTokenValid.state}
              variant="standard"
              color="primary"
              fullWidth
            >
              <InputLabel
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
              >
                <Typography variant="font14">Confirm Password</Typography>
              </InputLabel>
              <Input
                id="re-enter-password"
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
                type={showReEnterPassword.state ? "text" : "password"}
                value={reEnterPassword.state}
                onChange={(e) => {
                  isErrorOccured.update(false);
                  validationError.update({
                    ...validationError.state,
                    reEnterPassword: "",
                  });
                  reEnterPassword.update(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        showReEnterPassword.update(!showReEnterPassword.state)
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showReEnterPassword.state ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  validationError.state.reEnterPassword === "" ? false : true
                }
                id="standard-weight-helper-text"
              >
                {validationError.state.reEnterPassword}
              </FormHelperText>
            </FormControl>
          </Grid>

          {isErrorOccured.state && (
            <Grid item xs={12} mb={3}>
              <Alert severity="error">error : try again!</Alert>
            </Grid>
          )}

          {!isTokenValid.state && (
            <Grid item xs={12} mb={3}>
              <Alert severity="error">error : Token has expired!</Alert>
            </Grid>
          )}

          {isResetPassword.state && (
            <Grid item xs={12} mb={3}>
              <Alert severity="success">
                {isNewAccount.state
                  ? "Success : New password created successfully"
                  : "Success : Password reset successfully"}
              </Alert>
            </Grid>
          )}

          {isResetPassword.state ? (
            <Grid container justifyContent="center">
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate("/login", { replace: true })}
              >
                <Typography variant="buttonLabel">Login Here</Typography>
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" alignItems="center">
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={isLoader.state || !isTokenValid.state}
                  variant="contained"
                  onClick={(e) => {
                    checkValidatorOnSubmit() && callResetPassword();
                  }}
                >
                  <Typography variant="containedbuttonLabel" mx={3}>
                    {isNewAccount.state ? "Create password" : "Reset"}
                  </Typography>
                  {isLoader.state && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Grid>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgotPasswordReset;
