import React, { Fragment, useEffect } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateUpdate } from "UseStateUpdate";
import { COLORS } from "constants";
import { getBookDetails } from "service/BookService";
import {
  bookRecordedPage,
  largeModalStyle,
  rowHoverClickStyle,
  selectRetailSampleModalStyle,
} from "../common/Styles";
import { pageDetails } from "service/PageService";
import PlayBtn from "assets/images/playWave.png";
import PauseBtn from "assets/images/pauseWave.png";
import DeleteBtn from "assets/images/deleteBtn.png";
import { getAudioUrl, getImageUrl } from "../../utils/GetUrl";
import { ThreeDots } from "react-loader-spinner";
import { updateRetailSample } from "service/BookService";

let sound;

function SelectRetailSampleModal({ open, bookId, fetchBookChaptersWithAudio }) {
  const bookDetails = useStateUpdate(null);
  const selectedPageId = useStateUpdate(0);
  const selectedPageNumber = useStateUpdate(1);
  const pageContents = useStateUpdate([]);
  const isBookFetched = useStateUpdate(false);
  const isLoader = useStateUpdate(false);
  const isPlaying = useStateUpdate(false);
  const playingParagraphId = useStateUpdate(0);
  const classes = rowHoverClickStyle();
  const selectedPageContents = useStateUpdate([]);
  const totalAudioDuration = useStateUpdate(0.0);

  useEffect(() => {
    open.state && fetchBookDetails();
  }, [open.state]);

  useEffect(() => {
    pageContents.update([]);
    selectedPageId.state && fetchPageDetails();
  }, [selectedPageId.state]);

  useEffect(() => {
    const { pages } = bookDetails.state || {};
    pages?.length && selectedPageId.update(pages[0].pageId);
  }, [bookDetails.state]);

  useEffect(() => {
    totalAudioDuration.update((getTotalAudioDuration() / 60).toFixed(2));
  }, [selectedPageContents.state]);

  const fetchBookDetails = async () => {
    try {
      isBookFetched.update(false);
      const result = await getBookDetails(bookId);
      if (result.status === 200) {
        const { data } = result.data;
        bookDetails.update(data);
      }
    } catch (error) {
      console.error("Error fetching book details:", error);
    } finally {
      isBookFetched.update(true);
    }
  };

  const fetchPageDetails = async () => {
    try {
      const result = await pageDetails(selectedPageId.state);
      if (result.status === 200) {
        const { data } = result.data;
        pageContents.update(data);
      } else {
        console.error("Unexpected status code:", result.status);
      }
    } catch (error) {
      console.error("Error fetching page details:", error);
    }
  };

  const prepareAudio = (paragraph) => {
    pauseAudio();
    playingParagraphId.update(paragraph.paragraphId);
    let url = getAudioUrl(paragraph.audioUrl) + "?t=" + new Date().getTime();
    sound = new Audio(url);
    playAudio();
  };

  const playAudio = (e) => {
    sound?.play();
    sound?.addEventListener("ended", () => stopPlaying());
    sound && isPlaying.update(true);
  };

  const pauseAudio = () => {
    sound?.pause();
    playingParagraphId.update(0);
    isPlaying.update(false);
  };

  const stopPlaying = () => {
    playingParagraphId.update(0);
    isPlaying.update(false);
  };

  const getTotalAudioDuration = () => {
    return selectedPageContents.state.reduce(
      (totalDuration, pageContent) =>
        totalDuration + parseFloat(pageContent.audioDuration),
      0
    );
  };

  const handleRetailSampleButton = async () => {
    let selectedAudioUrls = [];
    selectedPageContents.state.forEach((pageContent) => {
      selectedAudioUrls.push(pageContent.audioUrl);
    });

    let result = await updateRetailSample(bookId, {
      audioUrl: selectedAudioUrls,
    });
    if (result.status === 200) {
      fetchBookChaptersWithAudio();
      handleClose();
    }
  };

  const handleClose = () => {
    selectedPageId.update(0);
    pageContents.update([]);
    isBookFetched.update(false);
    isLoader.update(false);
    isPlaying.update(false);
    playingParagraphId.update(0);
    selectedPageContents.update([]);
    open.update(false);
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={{ ...largeModalStyle, left: { xs: "50%", md: "50%" } }}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="font20b">
              Select audio for retail sample
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Paper variant={"outlined"}>
            <Grid container sx={{ minHeight: "60vh" }}>
              <Grid
                item
                xs={3}
                style={selectRetailSampleModalStyle.scrollableArea}
              >
                <Table size="small" aria-label="a dense table">
                  {isBookFetched.state && (
                    <TableBody>
                      {bookDetails.state.pages.map((page, index) => (
                        <TableRow
                          key={page.pageId}
                          hover={selectedPageId.state !== page.pageId}
                          classes={{ hover: classes.hover }}
                          onClick={() => {
                            selectedPageId.update(page.pageId);
                            selectedPageNumber.update(index + 1);
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRadius: 1,
                            }}
                          >
                            <Typography
                              variant={
                                selectedPageId.state === page.pageId
                                  ? "font15b"
                                  : "font15"
                              }
                              color={
                                selectedPageId.state === page.pageId &&
                                COLORS.primary
                              }
                            >
                              {`Page ${index + 1}`}
                            </Typography>
                            {selectedPageId.state === page.pageId && (
                              <img
                                src={getImageUrl(page.pageImageUrl)}
                                style={{ width: "100%" }}
                                alt="page_image"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </Grid>
              <Grid
                item
                xs={7}
                style={selectRetailSampleModalStyle.scrollableArea}
              >
                {pageContents.state.length > 0 ? (
                  <Table size="small">
                    <TableBody>
                      {pageContents.state.map((pageContent, index) => {
                        const isCurrentlyPlaying =
                          isPlaying.state &&
                          playingParagraphId.state === pageContent.paragraphId;

                        const handlePlayPauseClick = (e) => {
                          e.stopPropagation();
                          isCurrentlyPlaying
                            ? pauseAudio()
                            : prepareAudio(pageContent);
                        };

                        const isAlreadyAdded = selectedPageContents.state.some(
                          (selectedPageContent) =>
                            selectedPageContent.paragraphId ===
                            pageContent.paragraphId
                        );

                        const handleSelectButtonClick = (e) => {
                          e.stopPropagation();
                          pauseAudio();

                          if (!isAlreadyAdded) {
                            selectedPageContents.update([
                              ...selectedPageContents.state,
                              {
                                ...pageContent,
                                pageNumber: selectedPageNumber.state,
                                paragraphNumber: index + 1,
                              },
                            ]);
                          }
                        };

                        return (
                          <TableRow key={pageContent.paragraphId} hover>
                            <TableCell sx={{ borderRadius: 1 }}>
                              <Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography variant={"font15b"}>
                                    {`Paragraph ${index + 1}`}
                                  </Typography>
                                  <Stack
                                    spacing={3}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Tooltip
                                      title={
                                        isCurrentlyPlaying ? "Pause" : "Play"
                                      }
                                    >
                                      <IconButton
                                        color="primary"
                                        onClick={handlePlayPauseClick}
                                      >
                                        <img
                                          src={
                                            isCurrentlyPlaying
                                              ? PauseBtn
                                              : PlayBtn
                                          }
                                          style={bookRecordedPage.button}
                                          alt={
                                            isCurrentlyPlaying
                                              ? "pause"
                                              : "play"
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Button
                                      disabled={isAlreadyAdded}
                                      m={5}
                                      variant={"contained"}
                                      size="small"
                                      color="primary"
                                      onClick={handleSelectButtonClick}
                                    >
                                      <Typography variant={"font14"}>
                                        {`Select${isAlreadyAdded ? "ed" : ""}`}
                                      </Typography>
                                    </Button>
                                  </Stack>
                                </Stack>
                                <Typography variant={"font14"}>
                                  {pageContent.paragraphText}
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <ThreeDots height="15" color={COLORS.primary} />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={2}
                style={selectRetailSampleModalStyle.scrollableArea}
              >
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant={"font13b"}>
                          Selected List
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {selectedPageContents.state.map((pageContent, index) => {
                      const isCurrentlyPlaying =
                        isPlaying.state &&
                        playingParagraphId.state === pageContent.paragraphId;

                      const handlePlayPauseClick = (e) => {
                        e.stopPropagation();
                        isCurrentlyPlaying
                          ? pauseAudio()
                          : prepareAudio(pageContent);
                      };

                      const handleDeleteClick = (e) => {
                        e.stopPropagation();

                        const filterPagecontents =
                          selectedPageContents.state.filter(
                            (selectedPageContent) =>
                              pageContent.paragraphId !==
                              selectedPageContent.paragraphId
                          );

                        selectedPageContents.update(filterPagecontents);
                      };

                      return (
                        <TableRow key={pageContent.paragraphId} hover>
                          <TableCell sx={{ borderRadius: 1 }}>
                            <Stack>
                              <Typography variant={"font13b"}>
                                {`Page ${pageContent.pageNumber}`}
                              </Typography>{" "}
                              <Typography variant={"font12b"}>
                                {`Paragraph ${pageContent.paragraphNumber}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row">
                              <Tooltip
                                title={isCurrentlyPlaying ? "Pause" : "Play"}
                              >
                                <IconButton
                                  color="primary"
                                  onClick={handlePlayPauseClick}
                                >
                                  <img
                                    src={
                                      isCurrentlyPlaying ? PauseBtn : PlayBtn
                                    }
                                    style={bookRecordedPage.button}
                                    alt={isCurrentlyPlaying ? "pause" : "play"}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Delete"}>
                                <IconButton
                                  color="primary"
                                  onClick={handleDeleteClick}
                                >
                                  <img
                                    src={DeleteBtn}
                                    style={bookRecordedPage.button}
                                    alt={isCurrentlyPlaying ? "pause" : "play"}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Box m={2} display="flex" justifyContent="center">
              <Stack spacing={0.5} alignItems="center">
                <Button
                  disabled={
                    totalAudioDuration.state > 5.0 ||
                    totalAudioDuration.state < 0.1
                  }
                  variant="contained"
                  size="small"
                  onClick={handleRetailSampleButton}
                >
                  <Typography
                    variant="font14b"
                    color={COLORS.white}
                    mx={2}
                    noWrap
                  >
                    Create Retail Sample
                  </Typography>
                </Button>
                <Typography
                  variant="font13"
                  align="center"
                  color={totalAudioDuration.state > 5.0 && COLORS.inActiveRed}
                  dangerouslySetInnerHTML={{
                    __html:
                      totalAudioDuration.state < 0.1
                        ? "<strong>Select the audios</strong>"
                        : totalAudioDuration.state > 5.0
                        ? `The total audio duration of selected audios should not exceed 5 minutes. Currently, it's <strong>${totalAudioDuration.state} minutes</strong>. Please remove some selected audios from the list.`
                        : `The total audio duration of selected audios is <strong>${totalAudioDuration.state} minutes</strong>.<br><i>"You can select up to a total of 5 minutes of audio."</i>`,
                  }}
                />
              </Stack>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default SelectRetailSampleModal;
