import React, { Fragment } from "react";

import { Box, Divider, MenuItem, Stack, Typography } from "@mui/material";

import { COLORS } from "constants";
import { getFormattedDate } from "utils/DateTimeUtils";
import { markNotificationRead } from "service/NotificationsService";
import { useNavigate } from "react-router-dom";

const generateTypography = (text, variant, isRead) => (
  <Typography
    variant={isRead ? variant : variant + "b"}
    color={!isRead && COLORS.primary}
  >
    {text}
  </Typography>
);

function PrimaryNotifications({ notifications, fetchNotifications }) {
  const navigate = useNavigate();
  const callMarkNotificationRead = async (notificationId) => {
    const body = {
      notificationIds: [notificationId],
    };
    const result = await markNotificationRead(body);
    if (result.status === 200) {
      fetchNotifications();
    }
  };

  return notifications.state.length ? (
    notifications.state.map((notification) => (
      <Fragment key={notification.notificationId}>
        <MenuItem
          onClick={() => {
            navigate(notification.url);
            !notification.isRead &&
              callMarkNotificationRead(notification.notificationId);
          }}
        >
          <Box width="100%">
            <Stack>
              {generateTypography(
                notification.title,
                "font15",
                notification.isRead
              )}
              {generateTypography(
                notification.description,
                "font15",
                notification.isRead
              )}
              {generateTypography(
                getFormattedDate(notification.updatedAt),
                "font12",
                notification.isRead
              )}
            </Stack>
          </Box>
        </MenuItem>
        <Divider />
      </Fragment>
    ))
  ) : (
    <Box p={2} display="flex" justifyContent="center">
      No notifications
    </Box>
  );
}

export default PrimaryNotifications;
