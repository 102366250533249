import React, { Fragment, useEffect } from "react";
import { Stack, Avatar, Box, IconButton, Tooltip } from "@mui/material";
import broken_avatar from "assets/images/broken-avatar.png";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
import { UploadImageModal } from "components/modals";
import { getProfilePicUrl } from "utils/GetUrl";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { updateProfile } from "service/UserService";
import { profileStyle } from "components/common/Styles";

function ProfilePicture({ isViewUser, profileData, fetchProfileData }) {
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const openUploadModal = useStateUpdate(false);
  const isUploading = useStateUpdate(false);
  const profilePicture = useStateUpdate(broken_avatar);
  const isHover = useStateUpdate(false);

  useEffect(() => {
    profilePicture.update(
      profileData.state?.profileUrl
        ? getProfilePicUrl(profileData.state.profileUrl)
        : broken_avatar
    );
  }, [profileData.state]);

  const handleRemoveProfilePicture = async () => {
    const body = { profileUrl: null };
    const result = await updateProfile(body);
    result.status === 200 && fetchProfileData();
  };

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Fragment>
      <UploadImageModal
        {...{
          openUploadModal,
          calltoast,
          getProfilePicUrl,
          isUploading,
          fetchProfileData,
          profileData,
        }}
      />
      <Stack alignItems="center">
        <Avatar
          src={profilePicture.state}
          sx={profileStyle.avatar}
          onMouseEnter={() => {
            isHover.update(true);
          }}
        />
        {isHover.state && !isViewUser && (
          <Fragment>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              onMouseLeave={() => {
                isHover.update(false);
              }}
              sx={{
                ...profileStyle.avatar,
                ...profileStyle.hover,
              }}
            >
              {
                <Stack direction="row" spacing={1}>
                  <Tooltip
                    title={
                      profileData.state?.profileUrl
                        ? "Change photo"
                        : "Upload photo"
                    }
                  >
                    <IconButton
                      color="white"
                      size="small"
                      onClick={() => openUploadModal.update(true)}
                      sx={profileStyle.avatarBtn}
                    >
                      {profileData.state?.profileUrl ? (
                        <CachedOutlinedIcon />
                      ) : (
                        <FileUploadIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  {profileData.state?.profileUrl && (
                    <Tooltip title="Remove profile picture">
                      <IconButton
                        color="white"
                        size="small"
                        onClick={handleRemoveProfilePicture}
                        sx={profileStyle.avatarBtn}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              }
            </Box>
          </Fragment>
        )}
      </Stack>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default ProfilePicture;
