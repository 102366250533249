import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  CssBaseline,
  List,
  Divider,
  ListItem,
  ListItemText,
  Hidden,
  Typography,
} from "@mui/material";

import { Main, DrawerHeader } from "components/common/Styles";

import { DRAWER_WIDTH as drawerWidth } from "constants/otherConstant";
import { COLORS, USER_TYPES } from "constants";
import logo from "assets/images/logo-h.png";
import {
  useIsAdmin,
  useIsAuthor,
  useIsSuperAdmin,
  useIsAudioEngineer,
} from "utils/GetUserDetail";

import { DASHBOARD_MENU as dashBoardMenu } from "constants";

function MainDrawer(props) {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const urlParam = useLocation().search;
  const { open, window, children } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const isAdmin = useIsAdmin();
  const isAuthor = useIsAuthor();
  const isAudioEngineer = useIsAudioEngineer();
  const isSuperAdmin = useIsSuperAdmin();

  const drawer = (isMobile) => {
    const expandOrCollapseMenu = (expand, path) => {
      path && isMobile && open.update(!open.state);
      path && navigate(path);
    };

    return (
      <div>
        <DrawerHeader>
          <img src={logo} style={{ width: "85%" }} alt="logo" />
        </DrawerHeader>
        <Divider />
        <List>
          {dashBoardMenu.map((item, index) => (
            <Fragment key={item.label}>
              {((isAdmin && item.userTypes.indexOf(USER_TYPES.admin) > -1) ||
                (isAuthor && item.userTypes.indexOf(USER_TYPES.author) > -1) ||
                (isAudioEngineer &&
                  item.userTypes.indexOf(USER_TYPES.audioEngineer) > -1) ||
                (isSuperAdmin &&
                  item.userTypes.indexOf(USER_TYPES.superAdmin) > -1)) && (
                <ListItem
                  selected={item.path === pathname + urlParam}
                  sx={{
                    color:
                      item.path === pathname + urlParam && COLORS.secondary,
                  }}
                  button
                  key={item.label}
                  onClick={(e) => {
                    expandOrCollapseMenu(item.label, item.path);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="font15" noWrap>
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </Fragment>
          ))}
        </List>
      </div>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Hidden mdUp>
        <Drawer
          container={container}
          variant="temporary"
          open={open.state}
          onClose={() => open.update(!open.state)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: COLORS.drawerBackground,
              color: COLORS.white,
            },
          }}
        >
          {drawer(true)}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: COLORS.drawerBackground,
              color: COLORS.white,
            },
          }}
          sx={{
            display: { xs: "none", md: "block" },
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open.state}
        >
          {drawer(false)}
        </Drawer>
      </Hidden>
      <Main
        open={open.state}
        sx={{
          marginLeft: open.state
            ? { xs: "0px" }
            : { xs: "0px", md: `-${drawerWidth}px` },
        }}
      >
        {children}
      </Main>
    </Box>
  );
}

export default MainDrawer;
