import React from "react";

import { Typography, Grid, TableRow, TableCell } from "@mui/material";

function NoDataFound({ colSpanValue }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpanValue}>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="font15">NO DATA FOUND</Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default NoDataFound;
