export const checkBookStatus = (
  status,
  bookDetails,
  pageLength,
  message,
  isAuthor = false
) => {
  let warningMessage = "";
  let navigatePath = "";
  let isOpenModal = false;

  if (status !== bookDetails.status.statusCode) {
    warningMessage = message ? message : `Can't view this page right now.`;
    navigatePath = isAuthor
      ? "/dashboard"
      : `/projects/${bookDetails.projectId}`;

    isOpenModal = true;
  }
  if (pageLength === 0) {
    warningMessage = "Pages not found. The document is still being processed.";
    navigatePath = isAuthor ? "/dashboard" : `/projects`;

    isOpenModal = true;
  }

  return {
    warningMessage: warningMessage,
    navigatePath: navigatePath,
    isOpenModal: isOpenModal,
  };
};
