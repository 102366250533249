import React, { Fragment, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardActions,
  CardHeader,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { basetheme } from "components/common/Theme";
import { useStateUpdate } from "UseStateUpdate";
import { COLORS, BOOK_STATUS_CODES as BookStatusCodes } from "constants";

function BookCard(props) {
  const {
    isAddToAudioEngineerList,
    title,
    description,
    bookStatus,
    book,
    currentBookId,
    callAssignAudioEngineer,
  } = props;
  let navigate = useNavigate();
  const isHovered = useStateUpdate(false);
  const isButtonEnabled = useStateUpdate(false);

  useEffect(() => {
    if (BookStatusCodes.bookUploading || BookStatusCodes.bookProcessing)
      isButtonEnabled.update(true);
    else isButtonEnabled.update(false);
  }, []);

  const backgroundColor = () => {
    let color = COLORS.aliceBlue;
    if (isHovered.state) color = basetheme.palette.primary.main;
    return color;
  };

  const buttonFontColor = () => {
    let color = COLORS.aliceBlue;
    if (!isHovered.state) color = "primary.main";
    return color;
  };

  const checkBookStatus = () => {
    if (isAddToAudioEngineerList) {
      callAssignAudioEngineer();
    } else {
      const routes = {
        [BookStatusCodes.bookCreated]: (bookId) => currentBookId.update(bookId),
        [BookStatusCodes.bookUploaded]: (bookId) =>
          navigate(`/verifybook/${bookId}`),
        [BookStatusCodes.bookVerified]: (bookId) =>
          navigate(`/book-grouping/${bookId}`),
        [BookStatusCodes.bookRecording]: (bookId) =>
          navigate(`/voices/${bookId}`),
        [BookStatusCodes.bookRecorded]: (bookId) =>
          navigate(`/optimize-audio/${bookId}`),
        [BookStatusCodes.optimizationInProgress]: (bookId) =>
          navigate(`/optimize-audio/${bookId}`),
        [BookStatusCodes.audioOptimized]: (bookId) =>
          navigate(`/optimized-audio/${bookId}`),
        [BookStatusCodes.readyForProduction]: (bookId) =>
          navigate(`/ready-for-production/${bookId}`),
        [BookStatusCodes.completed]: (bookId) =>
          navigate(`/completed-book/${bookId}`),
      };

      const route = routes[bookStatus];
      if (route) {
        route(book.bookId);
      }
    }
  };

  const getBookStatus = () => {
    if (isAddToAudioEngineerList) {
      return "Add to list";
    }
    const statusOptions = {
      [BookStatusCodes.bookCreated]: "Upload book",
      [BookStatusCodes.bookUploaded]: "Verify book",
      [BookStatusCodes.bookVerified]: "Group chapters",
      [BookStatusCodes.chapterGrouped]: "Assign author",
      [BookStatusCodes.bookRecording]: "Record book",
      [BookStatusCodes.bookRecorded]: "Audio optimization",
      [BookStatusCodes.optimizationInProgress]: "Audio optimization",
      [BookStatusCodes.audioOptimized]: "Verify optimized audio",
      [BookStatusCodes.readyForProduction]: "Completed",
      [BookStatusCodes.completed]: "Completed",
    };

    return statusOptions[bookStatus] || "";
  };

  return (
    <Grid item xs={12} md={4} container alignItems="stretch">
      <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Fragment>
          <CardActions sx={{ justifyContent: "flex-end" }}></CardActions>
          <CardHeader
            title={title}
            titleTypographyProps={{ sx: { color: "primary.main" } }}
            subheader={description}
            subheaderTypographyProps={{ sx: { textAlign: "justify" } }}
            sx={{ marginX: 3 }}
          />
        </Fragment>

        <CardContent
          sx={{
            backgroundColor: backgroundColor(),
            marginTop: "auto",
            textAlign: "center",
            cursor: isButtonEnabled.state ? "pointer" : "no-drop",
          }}
          onMouseEnter={() => {
            isButtonEnabled.state && isHovered.update(true);
          }}
          onMouseLeave={() => {
            isHovered.update(false);
          }}
          onClick={() => {
            if (isButtonEnabled.state) {
              checkBookStatus();
            }
          }}
        >
          <Typography variant="font20b" color={buttonFontColor()}>
            {getBookStatus()}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default BookCard;
