import { apiCall } from "service/AuthProvider";

const SERVICE_URL = "email-template-service/";

export const listEmailTemplates = async () => {
  const url = `${SERVICE_URL}template`;
  return apiCall("get", url);
};

export const listEmailTemplatesType = async () => {
  const url = `${SERVICE_URL}template/types`;
  return apiCall("get", url);
};

export const createEmailTemplate = async (body) => {
  const url = `${SERVICE_URL}template`;
  return apiCall("post", url, body);
};

export const updateEmailTemplate = async (body, emailTemplateId) => {
  const url = `${SERVICE_URL}template/${emailTemplateId}`;
  return apiCall("put", url, body);
};
