import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { COLORS } from "constants";
import { primaryButtonHover } from "components/common/Styles";

function TextToRecordView(props) {
  const {
    isPlaying,
    pauseAudio,
    playAudio,
    textToRecord,
    audioLoaded,
    isSilenceRecording,
  } = props;

  return (
    <Box pt={3}>
      <Box display="flex" justifyContent="center">
        <Typography variant="font18" fontStyle="italic">
          {isSilenceRecording.state
            ? "Record in silence for best results."
            : "Read the below text while recording."}
        </Typography>
      </Box>
      {isSilenceRecording.state ? (
        <Box
          width="100%"
          boxShadow={`0px 2px 4px ${COLORS.primary50t}`}
          sx={{ backgroundColor: COLORS.white, borderRadius: 5, padding: 3 }}
        >
          <div style={{ width: "95%" }} id="waveform"></div>
        </Box>
      ) : (
        <Box
          boxShadow={`0px 2px 4px ${COLORS.primary50t}`}
          sx={{ backgroundColor: COLORS.white, borderRadius: 5, padding: 3 }}
        >
          <Typography variant="font23">{textToRecord.state}</Typography>
        </Box>
      )}
      {audioLoaded.state && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="outlined"
            sx={primaryButtonHover}
            onClick={() => {
              isPlaying.state ? pauseAudio() : playAudio();
            }}
          >
            <Typography variant="font15b">
              {isPlaying.state ? "Pause" : "Play"}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default TextToRecordView;
