import React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Stack,
  IconButton,
  Link,
  Tooltip,
  InputBase,
  Divider,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import NoDataFound from "components/common/NoDataFound";
import PaginationRow from "components/common/PaginationRow";
import { COLORS } from "constants";
import { getFormattedDate } from "utils/DateTimeUtils";
import { useNavigate } from "react-router-dom";
import { ENTITY_TYPE as EntityType } from "constants";

const headCells = [{ id: "createdAt", label: "Date" }];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip
              title={
                orderBy === headCell.id
                  ? order === "desc"
                    ? "Sorted descending"
                    : "Sorted ascending"
                  : "Sort"
              }
              placement="top"
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="font12b" color={COLORS.primary} noWrap>
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
        <TableCell>
          <Typography variant="font12b" color={COLORS.primary} noWrap>
            Logs
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const logMessage = (log) => {
  let logMessage = "";
  if (log.entity === EntityType.page) {
    logMessage = (
      <Typography variant="font12">
        <font color={COLORS.primary}>
          <b>{`Page ${log.messageData.data?.pageNumber ?? ""}`}</b>
        </font>
        {` recording has been completed`}
      </Typography>
    );
  } else if (log.entity === EntityType.paragraph) {
    logMessage = (
      <Typography variant="font12">
        <font color={COLORS.secondary}>
          <b>{`Paragraph ${log.messageData.data?.paragraphNumber ?? ""}`}</b>
        </font>
        {` of `}
        <font color={COLORS.primary}>
          <b>{`page ${log.messageData.data?.pageNumber ?? ""}`}</b>
        </font>
        {` has been recorded`}
      </Typography>
    );
  }

  return logMessage;
};

function RecordLogsTable(props) {
  const {
    callGetRecordingLogs,
    isFetching,
    filterValue,
    rowsPerPage,
    page,
    pageCount,
    order,
    orderBy,
    recordingLogs,
    bookId,
  } = props;

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy.state === property && order.state === "asc";
    order.update(isAsc ? "desc" : "asc");
    orderBy.update(property);
  };
  const logsDataRows = recordingLogs.state.map((log, index) => (
    <TableRow
      key={log.AuditLogId}
      sx={{
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
    >
      <TableCell>
        <Typography variant="font12">
          {getFormattedDate(log.createdAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          href=""
          underline="none"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/voices/${bookId}?page=${
                log.messageData.data?.pageNumber ?? 1
              }&paragraph=${log.messageData.data?.paragraphNumber ?? 1}`
            );
          }}
        >
          {logMessage(log)}
        </Link>
      </TableCell>
    </TableRow>
  ));

  let tableContent;

  if (isFetching.state) {
    tableContent = (
      <TableRow>
        <TableCell colSpan={2}>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </TableCell>
      </TableRow>
    );
  } else if (recordingLogs.state.length === 0) {
    tableContent = <NoDataFound colSpanValue={2} />;
  } else {
    tableContent = logsDataRows;
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Grid container p={2}>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack spacing={1} alignItems="flex-end">
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, sm: 4 }}
                alignItems="center"
              >
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { xs: 250, sm: 350 },
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, width: { xs: 200, sm: 300 } }}
                    placeholder="Search here"
                    value={filterValue.state}
                    onChange={(e) => {
                      filterValue.update(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        event.preventDefault();
                        if (event.target.value !== "") {
                          callGetRecordingLogs();
                        }
                      }
                    }}
                  />
                  {filterValue.state !== "" && (
                    <Tooltip title="Clear Text" placement="top">
                      <IconButton
                        onClick={(e) => {
                          filterValue.update("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Search" placement="top">
                    <IconButton
                      sx={{
                        backgroundColor: "primary.light",
                        borderRadius: "0%",
                      }}
                      onClick={(e) => {
                        if (filterValue.state !== "") {
                          callGetRecordingLogs();
                        }
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Paper>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead
                order={order.state}
                orderBy={orderBy.state}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {tableContent}
                {recordingLogs.state.length > 0 && (
                  <PaginationRow
                    perPage={rowsPerPage}
                    page={page}
                    pageCount={pageCount}
                    colSpanValue={2}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Divider />
      </Paper>
    </Grid>
  );
}

export default RecordLogsTable;
