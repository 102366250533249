const workercode = () => {
  onmessage = function (e) {
    let channelData = e.data;
    let seconds = 2;
    let arrayLimit = seconds * 44100;
    let rmsSum = 0;
    let n = 0; //back flag
    let m = 0; //index for tempbuffer;
    let silenceCount = 0;
    let tempBufferArray = new Float32Array(channelData.length);
    for (let i = 0; i < channelData.length; i++) {
      let square = channelData[i] * channelData[i];
      let rms = square / arrayLimit;
      rmsSum = rmsSum + rms;
      if (i - n >= arrayLimit) {
        let tempsquare = channelData[n] * channelData[n];
        let temprms = tempsquare / arrayLimit;
        rmsSum = rmsSum - temprms;
      }
      let rmsTotal = Math.sqrt(rmsSum);
      let decibel = 20 * Math.log10(rmsTotal);
      if (i - n >= arrayLimit) {
        if (decibel < -45) {
          silenceCount++;
          n = parseFloat(i);
          let tempsquare = channelData[i] * channelData[i];
          rmsSum = tempsquare / arrayLimit;
        } else {
          tempBufferArray[m] = channelData[n];
          n++;
          m++;
        }
      }
      if (i === channelData.length - 1) {
        for (let j = n; j < channelData.length; j++) {
          tempBufferArray[m] = channelData[j];
          m++;
        }
      }
    }
    postMessage({
      count: silenceCount,
      seconds: seconds,
      data: tempBufferArray.slice(0, m),
    });
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;
