export const getAudioLength = (file) => {
  const audio = new Audio();
  audio.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    audio.addEventListener("loadedmetadata", () => {
      const duration = audio.duration;
      resolve(duration);
    });

    audio.addEventListener("error", (err) => {
      reject(err);
    });
  });
};
