import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { COLORS } from "constants";
import AccessDenied from "components/common/AccessDenied";
import { useIsAuthorOrEngineer, useIsAdmin } from "utils/GetUserDetail";
import { useStateUpdate } from "UseStateUpdate";
import {
  listEmailTemplates,
  updateEmailTemplate,
  createEmailTemplate,
} from "service/EmailTemplateService";

import Toast from "components/common/Toast";
import EmailTemplateLeftSection from "./sections/EmailTemplateLeftSection";
import EmailTemplateRightSection from "./sections/EmailTemplateRightSection";
import { isEmptyString, isValidEmail } from "utils/FormValidator";

function EmailTemplates() {
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const isAdmin = useIsAdmin();
  const emailTemplateType = useStateUpdate("");
  const emailFromAddress = useStateUpdate("");
  const emailSubject = useStateUpdate("");
  const emailText = useStateUpdate("");
  const emailTemplateCode = useStateUpdate("");
  const codeForRender = useStateUpdate("");
  const emailTemplates = useStateUpdate(null);
  const selectedEmailTemplate = useStateUpdate(null);
  const isHideLeft = useStateUpdate(false);
  const isUpdateTemplate = useStateUpdate(false);
  const isUpdating = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const validationError = useStateUpdate({
    emailTemplateCode: "",
    emailTemplateType: "",
    emailFromAddress: "",
    emailSubject: "",
    emailText: "",
  });

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  useEffect(() => {
    const { state } = selectedEmailTemplate;
    emailTemplateType?.update(state?.emailTemplateType || "");
    emailFromAddress?.update(state?.emailFromAddress || "");
    emailSubject?.update(state?.emailSubject || "");
    emailText?.update(state?.emailText || "");
    emailTemplateCode?.update(state?.templateCode || "");
    codeForRender?.update(state?.templateCode || "");
  }, [selectedEmailTemplate.state]);

  useEffect(() => {
    if (emailTemplateCode.state === "")
      codeForRender.update(emailTemplateCode.state);
  }, [emailTemplateCode.state]);

  const callUpdateOrCreateFunction = async () => {
    isUpdating.update(true);
    const body = {
      templateCode: emailTemplateCode.state,
      emailTemplateType: emailTemplateType.state,
      emailText: emailText.state,
      emailSubject: emailSubject.state,
      emailFromAddress: emailFromAddress.state,
    };
    if (isUpdateTemplate.state) {
      const result = await updateEmailTemplate(
        body,
        selectedEmailTemplate.state.emailTemplateId
      );
      if (result.status === 200) {
        fetchEmailTemplates();
        calltoast("success", "Email template has been updated.");
      } else {
        calltoast("error", UNEXPECTED_ERROR_MESSAGE);
      }
    } else {
      const result = await createEmailTemplate(body);
      if (result.status === 200) {
        fetchEmailTemplates();
        calltoast("success", "Email template has been created.");
      } else {
        calltoast("error", UNEXPECTED_ERROR_MESSAGE);
      }
    }
    isUpdating.update(false);
  };

  const fetchEmailTemplates = async () => {
    let result = await listEmailTemplates();
    result.status === 200 && emailTemplates.update(result.data.data);
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  function checkValidatorOnSubmit() {
    const { state: emailTemplateCodeValue } = emailTemplateCode;
    const { state: emailTemplateTypeValue } = emailTemplateType;
    const { state: emailFromAddressValue } = emailFromAddress;
    const { state: emailSubjectValue } = emailSubject;
    const { state: emailTextValue } = emailText;
    const newValidationError = {
      emailTemplateCode: "",
      emailTemplateType: "",
      emailFromAddress: "",
      emailSubject: "",
      emailText: "",
    };
    if (isEmptyString(emailTemplateCodeValue)) {
      newValidationError.emailTemplateCode = "Code is required";
    }
    if (isEmptyString(emailFromAddressValue)) {
      newValidationError.emailFromAddress = "Email template type is required";
    } else if (!isValidEmail(emailFromAddressValue)) {
      newValidationError.emailFromAddress = "Email is not valid.";
    }
    if (isEmptyString(emailTemplateTypeValue)) {
      newValidationError.emailTemplateType = "Email template type is required";
    }
    if (isEmptyString(emailSubjectValue)) {
      newValidationError.emailSubject = "Email subject is required";
    }
    if (isEmptyString(emailTextValue)) {
      newValidationError.emailText = "Email Text is required";
    }
    validationError.update(newValidationError);
    return Object.values(newValidationError).every((value) => value === "");
  }

  const isButtonDisabled = () => {
    return [
      emailTemplateCode.state,
      emailTemplateType.state,
      emailFromAddress.state,
      emailSubject.state,
      emailText.state,
    ].some((value) => value === "");
  };

  const resetvalidations = () => {
    validationError.update({
      emailTemplateCode: "",
      emailTemplateType: "",
      emailFromAddress: "",
      emailSubject: "",
      emailText: "",
    });
  };

  return isAuthorOrEngineer || isAdmin ? (
    <AccessDenied />
  ) : (
    <Box p={5}>
      <Box mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Email Templates
        </Typography>
      </Box>
      <Grid container spacing={2} mb={2}>
        <EmailTemplateLeftSection
          {...{
            isHideLeft,
            isUpdateTemplate,
            selectedEmailTemplate,
            emailTemplates,
            resetvalidations,
          }}
        />
        <EmailTemplateRightSection
          {...{
            isHideLeft,
            isUpdateTemplate,
            emailTemplateCode,
            codeForRender,
            emailTemplateType,
            emailFromAddress,
            emailSubject,
            emailText,
            callUpdateOrCreateFunction,
            checkValidatorOnSubmit,
            validationError,
            isButtonDisabled,
            isEmptyString,
            isValidEmail,
            isUpdating,
          }}
        />
      </Grid>{" "}
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  );
}

export default EmailTemplates;
