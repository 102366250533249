import React, { useEffect } from "react";
import { Box, Container, IconButton, Stack } from "@mui/material";

import BackBtn from "assets/images/back.png";
import { useStateUpdate } from "UseStateUpdate";
import TextToRecordView from "./TextToRecordView";
import RecordingDiv from "./RecordingDiv";

import { NoisyModal, QualityReportModal } from "components/modals";
import {
  getAudioBufferFromBlob,
  getContext,
} from "components/Voices/sections/utils";
import { COLORS } from "constants";
import WaveSurfer from "wavesurfer.js";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
import { recordingDiv } from "components/common/Styles";

const MicRecorder = require("mic-recorder-to-mp3");

const recorder = new MicRecorder({
  bitRate: 320,
});

let recordTimer;
let waveSurfer;
let sound;

function SampleRecordingPage(props) {
  const { isSilenceRecording, isRecordingPage } = props;
  const classes = recordingDiv();
  const isRecording = useStateUpdate(false);
  const seconds = useStateUpdate(0);
  const timer = useStateUpdate("00:00:00");
  const showNoisyModal = useStateUpdate(false);
  const noisyModalMessage = useStateUpdate("");
  const noisyModalType = useStateUpdate(null);
  const isActive = useStateUpdate(false);
  const context = useStateUpdate(null);
  const isPlaying = useStateUpdate(false);
  const sampleSampleRate = useStateUpdate(null);
  const sampleBitRate = useStateUpdate(null);
  const sampleChannelCount = useStateUpdate(null);
  const sampleDecibel = useStateUpdate(null);
  const showQualityReportModal = useStateUpdate(false);
  const audioLoaded = useStateUpdate(false);
  const isWaveLoading = useStateUpdate(false);

  const textToRecord = useStateUpdate(
    "Out on the lake, flopping trout were slapping the surface. They were hoping to catch one of the squadron of flies that buzzed about. The heaven-leaking light added a golden tint to the face of the lake and it was paradise. A startling eureka moment came unbidden, which involved the beauty of the natural world. I kept it to myself. The nipping midges didn’t take away from the pleasure of that day. I can still see the rain-pearled grass in my mind’s eye. I remember the saccharine sweet smell of that grass. I remember that the water tasted like the nectar of the gods."
  );

  useEffect(() => {
    context.update(getContext());
    return () => {
      waveSurfer?.destroy();
    };
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive.state) {
      interval = setInterval(() => {
        startTimer(seconds.state + 1);
        seconds.update((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!isActive.state && seconds.state !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive.state, seconds.state]);

  const startRecording = (e) => {
    recorder
      .start()
      .then(() => {
        pauseAudio();
        isRecording.update(true);
        isActive.update(true);
      })
      .catch((e) => {
        console.error(`ERROR ${e}`);
      });
  };

  const stopRecording = (e) => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        let audio_length = stopTimer();
        if (audio_length === 0) {
          noisyModalMessage.update("Audio is too short.");
          noisyModalType.update("warning");
          showNoisyModal.update(true);
        } else if (audio_length === 2) {
          noisyModalMessage.update(
            `The audio is too long; the maximum length is 3 minutes.`
          );
          noisyModalType.update("warning");
          showNoisyModal.update(true);
        } else {
          getRecordingBuffer(blob);
          prepareAudio(buffer, blob);
        }
      })
      .catch((e) => {
        alert("We could not retrieve your message");
      });
    isRecording.update(false);
  };

  const startTimer = (seconds) => {
    if (isSilenceRecording.state && seconds >= 10) {
      stopRecording();
    } else {
      let sec_num = Math.floor(seconds);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let secondsIs = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (secondsIs < 10) {
        secondsIs = `0${secondsIs}`;
      }
      timer.update(`${hours}:${minutes}:${secondsIs}`);
    }
  };

  const stopTimer = () => {
    clearInterval(recordTimer);
    let audio_length;
    if (seconds.state <= 1) {
      audio_length = 0;
    } else if (seconds.state >= 180) {
      audio_length = 2;
    } else {
      audio_length = 1;
    }
    seconds.update(0);
    timer.update("00:00:00");
    isActive.update(false);
    return audio_length;
  };

  const getRecordingBuffer = async (blob) => {
    return getAudioBufferFromBlob(blob, context.state)
      .then((response) => {
        let channelData = response.getChannelData(0);
        let rms = getRmsValue(channelData);
        let decibel = 20 * Math.log10(rms);
        let samplingRate = response.sampleRate;
        let channelCount = response.numberOfChannels == 1 ? "mono" : "stereo";
        let duration = response.duration || 1;
        let bitrate = Math.ceil((blob.size * 0.008) / duration);

        if (isSilenceRecording.state) {
          getDecibelValue(channelData, response.duration);
          showNoisyModal.update(true);
        } else {
          sampleSampleRate.update(samplingRate);
          sampleBitRate.update(bitrate);
          sampleChannelCount.update(channelCount);
          sampleDecibel.update(Math.round(decibel * 100) / 100);
          showQualityReportModal.update(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRmsValue = (channelData) => {
    let sum = 0;
    for (let i in channelData) {
      let square = channelData[i] * channelData[i];
      sum = sum + square;
    }
    let rms = Math.sqrt(sum / channelData.length);
    return rms;
  };

  const getDecibelValue = (channelData, duration) => {
    let message = "";
    let acceptResult = false;
    let rms = getRmsValue(channelData);
    let decibel = 20 * Math.log10(rms);
    if (decibel < -60) {
      message = "Silent audio, noise free environment.";
      noisyModalType.update("success");
      acceptResult = true;
    } else if ((decibel > -60) & (decibel < -35)) {
      message = "Silent audio, acceptable environment.";
      noisyModalType.update("success");
      acceptResult = true;
    } else if ((decibel > -35) & (decibel < -25)) {
      message =
        "Very noisy environment. Please check your surroundings and try again.";
      noisyModalType.update("error");
    } else {
      message = "Silence recording not accepted due to a rowdy environment.";
      noisyModalType.update("error");
    }
    noisyModalMessage.update(message);
    return acceptResult;
  };

  const prepareAudio = (buffer, blob) => {
    const file = new File(buffer, "me-at-thevoice.mp3", {
      type: blob.type,
      lastModified: Date.now(),
    });
    let url = URL.createObjectURL(file);
    if (isSilenceRecording.state) {
      waveSurfer?.destroy();
      loadWavesurfer(url);
    } else {
      sound = new Audio(url);
    }
    audioLoaded.update(true);
  };

  const loadWavesurfer = (urlIs) => {
    isWaveLoading.update(true);
    waveSurfer = WaveSurfer.create({
      container: document.querySelector("#waveform"),
      barWidth: 3,
      barRadius: 3,
      barGap: 2,
      barMinHeight: 1,
      cursorWidth: 1,
      backend: "WebAudio",
      progressColor: COLORS.primary,
      responsive: true,
      waveColor: "#C4C4C4",
      cursorColor: "transparent",
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            "background-color": "#000",
            color: "#fff",
            padding: "2px",
            "font-size": "10px",
          },
          customWaveColor: function (percents) {
            return percents <= 50 ? "#ff0000" : "#00ff00";
          },
        }),
      ],
    });
    waveSurfer.load(urlIs);
    waveSurfer.on("ready", () => {
      isWaveLoading.update(false);
    });
    waveSurfer.on("finish", () => {
      isPlaying.update(false);
    });
  };

  const playAudio = (e) => {
    if (isSilenceRecording.state) {
      waveSurfer?.play();
      waveSurfer && isPlaying.update(true);
    } else {
      sound?.play();
      sound?.addEventListener("ended", () => stopPlaying());
      sound && isPlaying.update(true);
    }
  };

  const pauseAudio = () => {
    if (isSilenceRecording.state) {
      waveSurfer?.pause();
      isPlaying.update(false);
    } else {
      sound?.pause();
      isPlaying.update(false);
    }
  };
  const stopPlaying = () => {
    isPlaying.update(false);
  };

  return (
    <Box px={5}>
      <NoisyModal {...{ showNoisyModal, noisyModalMessage, noisyModalType }} />
      <QualityReportModal
        {...{
          showQualityReportModal,
          sampleSampleRate,
          sampleBitRate,
          sampleChannelCount,
          sampleDecibel,
        }}
      />

      <Container maxWidth="md">
        <Box pt={2}>
          <IconButton
            className={classes.recordButton}
            size="large"
            onClick={(e) => {
              isRecordingPage.update(false);
              sound?.pause();
              waveSurfer?.destroy();
            }}
          >
            <img src={BackBtn} width="20px" alt="Record" />
          </IconButton>
        </Box>

        <Stack spacing={3}>
          <TextToRecordView
            {...{
              isPlaying,
              pauseAudio,
              playAudio,
              textToRecord,
              audioLoaded,
              isSilenceRecording,
            }}
          />
          <RecordingDiv
            {...{
              isRecording,
              timer,
              stopRecording,
              startRecording,
              isSilenceRecording,
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
}

export default SampleRecordingPage;
