import React from "react";
import { Typography, Box, Grid, IconButton, Stack } from "@mui/material";
import Record from "assets/images/mic.png";
import StopRecord from "assets/images/stop_green.png";
import { recordingDiv } from "components/common/Styles";

function RecordingDiv(props) {
  const {
    isRecording,
    timer,
    stopRecording,
    startRecording,
    isSilenceRecording,
  } = props;
  const classes = recordingDiv();

  const renderStopButton = () => {
    return (
      <Stack justifyContent="center" alignItems="center">
        {isSilenceRecording.state && (
          <Typography variant="font16" color="primary.main">
            Recording will stop after 10 seconds.
          </Typography>
        )}
        <Typography variant="font20b" color="primary.main">
          {timer.state}
        </Typography>
        {!isSilenceRecording.state && (
          <IconButton
            className={classes.stopRecordButton}
            size="large"
            onClick={(e) => {
              stopRecording();
            }}
          >
            <img src={StopRecord} alt="Stop Record" />
          </IconButton>
        )}
      </Stack>
    );
  };

  const renderStartButton = () => {
    return (
      <IconButton
        className={classes.recordButton}
        size="large"
        onClick={(e) => {
          startRecording();
        }}
      >
        <img src={Record} width="70px" alt="Record" />
      </IconButton>
    );
  };

  const renderButtonComponent = () => {
    let buttonComponent;

    if (isRecording.state) {
      buttonComponent = renderStopButton();
    } else {
      buttonComponent = renderStartButton();
    }

    return buttonComponent;
  };

  return (
    <Box
      sx={{
        pt: "20px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container>
        <Grid item xs={12} container justifyContent="center">
          {renderButtonComponent()}
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecordingDiv;
