import React from "react";
import { Box, Typography, Button, Link, Container } from "@mui/material";
import { primaryButtonHover } from "components/common/Styles";
const helpPageStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  margin: "0 auto",
};

const headingStyle = {
  color: "rgba(39, 56, 145, 1)",
  fontWeight: "bold",
  marginBottom: "20px",
};

const paragraphStyle = {
  color: "#4a4a4a",
  fontSize: "1.2rem",
  lineHeight: 1.6,
  marginBottom: "20px",
};

const solutionBoxStyle = {
  backgroundColor: "#f7f9fc",
  padding: "20px",
  borderRadius: "8px",
  width: "100%",
  margin: "20px 0",
};

const linkStyle = {
  color: "rgba(34,51,120,1)",
  textDecoration: "underline",
};

function HelpPage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Box sx={helpPageStyle}>
        <Typography variant="h3" sx={headingStyle}>
          Microphone Permission Help
        </Typography>

        <Typography sx={paragraphStyle}>
          For MyAudiobookRecorder to function properly, microphone access is
          required to record audio. If you are experiencing issues with
          microphone permissions in your browser, follow the guide below for
          troubleshooting. These instructions should work across most modern
          browsers such as Google Chrome, Firefox, Safari, and others.
        </Typography>

        <Typography sx={paragraphStyle}>
          When you attempt to record audio, your browser will prompt you to
          grant microphone access. The permission prompt may offer different
          options depending on the browser and the situation:
        </Typography>

        <Typography sx={paragraphStyle}>
          <strong>1. Allow</strong>: This option grants the website permission
          to access your microphone permanently. This means the website can
          access the microphone without asking again, unless you change your
          browser settings.
        </Typography>

        <Typography sx={paragraphStyle}>
          <strong>2. Allow This Time</strong>: If you choose this option, the
          website will be allowed to use the microphone only for the current
          session. Once you leave the page or close the browser, the permission
          will need to be granted again.
        </Typography>

        <Typography sx={paragraphStyle}>
          <strong>3. Never Allow</strong>: If you select this option, the
          browser will block the website from accessing the microphone entirely.
          You will need to manually adjust your settings to re-enable microphone
          access.
        </Typography>

        <Box sx={solutionBoxStyle}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              color: "rgba(39, 56, 145, 1)",
            }}
          >
            Common Solutions for Microphone Permission Issues
          </Typography>

          <Typography sx={paragraphStyle}>
            If you have selected "Never Allow" by mistake, you will need to go
            to your browser's settings to grant microphone permissions again.
          </Typography>

          <Typography sx={paragraphStyle}>
            <strong>For All Browsers:</strong> If the microphone is not working,
            follow these common steps to ensure the settings are configured
            correctly:
          </Typography>

          <ul>
            <li>
              Ensure your microphone is connected and functioning properly
              (check your device's system settings).
            </li>
            <li>Make sure your browser is up-to-date.</li>
            <li>
              Ensure there are no other applications or browser tabs using the
              microphone.
            </li>
          </ul>

          <Typography sx={paragraphStyle}>
            If you're still facing issues, you can manually enable microphone
            access by following the steps below for your browser:
          </Typography>

          <Typography sx={{ ...paragraphStyle, fontWeight: "bold" }}>
            Steps for Enabling Microphone Permissions:
          </Typography>

          <ul>
            <li>Open your browser's settings page.</li>
            <li>
              Look for the "Privacy and Security" section, where you'll find
              "Site Settings" or "Permissions."
            </li>
            <li>
              Find the "Microphone" settings and make sure it's set to "Allow"
              for MyAudiobookRecorder.
            </li>
          </ul>

          <Typography sx={paragraphStyle}>
            If you need detailed instructions for a specific browser, feel free
            to visit the following resources:
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <Link
              href="https://support.google.com/chrome/answer/2693767"
              target="_blank"
              sx={linkStyle}
            >
              Google Chrome - Enable Microphone
            </Link>
            <Link
              href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
              target="_blank"
              sx={linkStyle}
            >
              Firefox - Enable Microphone
            </Link>

            <Link
              href="https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857"
              target="_blank"
              sx={linkStyle}
            >
              Windows with Edge - Enable Microphone
            </Link>
          </Box>
        </Box>

        <Typography sx={paragraphStyle}>
          If these steps do not resolve your issue, please reach out to our
          support team for further assistance.
        </Typography>

        <Button
          variant="outlined"
          size="large"
          sx={{
            bgcolor: "rgba(39, 56, 145, 1)",
            color: "#ffffff",
            "&:hover": {
              bgcolor: "rgba(39, 56, 145, 1)",
            },
            textTransform: "none",
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
          onClick={() => window.history.back()}
        >
          Go Back
        </Button>
      </Box>
    </Container>
  );
}

export default HelpPage;
