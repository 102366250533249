const workercode = () => {
  onmessage = function (e) {
    let channelData = e.data.channelData;
    let start = e.data.start;
    let end = e.data.end;
    let sampleRate = e.data.sampleRate;
    let duration = e.data.duration;
    let frameCount = (duration - (end - start)) * sampleRate;
    let loopLimit = duration * sampleRate;

    let toChanData = new Float32Array(frameCount);
    for (let i = 0, j = 0; i < loopLimit; i++) {
      if (i === Math.round(start * sampleRate)) {
        i = Math.round(end * sampleRate);
      }
      toChanData[j] = channelData[i];
      j++;
    }
    postMessage({
      channelData: toChanData,
    });
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const clipworker_script = URL.createObjectURL(blob);

module.exports = clipworker_script;
