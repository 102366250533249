import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";

function AccessDenied() {
  return (
    <Box p={15}>
      <Alert severity="error">
        <AlertTitle>Access denied.</AlertTitle>
        You don't have permission to view this page. Go to the
        <strong> dashboard.</strong>
      </Alert>
    </Box>
  );
}

export default AccessDenied;
