import React, { useContext, useEffect } from "react";
import {
  Box,
  Fab,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Switch,
  Typography,
  Tooltip,
} from "@mui/material";

import { useStateUpdate } from "UseStateUpdate";
import {
  WaveEditModal,
  WarningModal,
  CreateThreadModal,
  ConfirmModal,
  NoisyModal,
  PageViewModal,
} from "../modals";
import LeftScrollablePageView from "./sections/LeftScrollablePageView";
import RightScrollableParagraphView from "./sections/RightScrollableParagraphView";
import ContentView from "./sections/ContentView";
import RecordPageFooter from "./sections/RecordPageFooter";
import RecordDiv from "./sections/RecordDiv";
import ZoomInAndOut from "./sections/ZoomInAndOut";
import { getContext } from "./sections/utils";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { scrollableSide, fabStyles } from "components/common/Styles";
import { useLocation } from "react-router-dom";
import {
  EXTRA_PAGES_INDEX as ExtraPagesIndex,
  MOBILE_MAX_WIDTH,
} from "constants/otherConstant";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { checkBookStatus } from "utils/Checkbookstatus";
import RecordGuideLine from "./sections/RecordGuideLine";
import { useIsAuthor } from "utils/GetUserDetail";
import { COLORS } from "constants";
import UnRecordedPagesModal from "components/modals/UnRecordedPagesModal";
import { RECORDING_PERCENTAGE_THRESHOLD } from "constants/otherConstant";
import { RecordingPageContext } from "context/context";

const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;

function Voices({ isToolbarHidden }) {
  const {
    selectedIndex,
    totalPages,
    openGuideLineModal,
    isRecordingCompleted,
    callUpdateBookStatus,
    openUnRecordedPagesModal,
    pagesNotRecorded,
    pageContents,
    selectedParagraphIndex,
    isParagraphActionsEnabled,
    openConfirmModal,
    isEditing,
    isRecording,
    pages,
    isAudioUploading,
    keyPressed,
    pauseAudio,
    isRecordingEnabled,
    openPageViewModal,
    pageUrl,
    showPagesType,
    selectedParagraph,
    context,
    prepareAudio,
    bookDetails,
    openWaveEditModal,
    textToRecord,
    fetchPageDetails,
    openCreateThreadModal,
    threadTitle,
    description,
    threadPage,
    threadParagraph,
    openWaveEditModalRef,
    isAddingParagraphRef,
    openPageViewModalRef,
    openConfirmModalRef,
    isAddingParagraph,
    openWarningModalRef,
    showNoisyModalRef,
    openCreateThreadModalRef,
    openGuideLineModalRef,
    isEditingRef,
    showNoisyModal,
    openWarningModal,
    warningModalMessage,
    navigateTo,
    recordedUserId,
    isFetchingPageContent,
    isDeleting,
    bookId,
    noisyModalType,
    noisyModalMessage,
    isHideLeft,
    confirmModalData,
    message,
    isFetching,
    checkIsRecorded,
    fetchBookDetails,
    checkIsRecordedUserIsAdmin,
  } = useContext(RecordingPageContext);
  const classes = scrollableSide();
  const classes2 = fabStyles();
  const search = useLocation().search;
  const isAuthor = useIsAuthor();
  const pageParam = useStateUpdate(
    parseInt(new URLSearchParams(search).get("page"))
  );
  const paragraphParam = useStateUpdate(
    parseInt(new URLSearchParams(search).get("paragraph"))
  );

  useEffect(() => {
    // Sync refs with the current state values
    openWaveEditModalRef.current = openWaveEditModal.state;
    isAddingParagraphRef.current = isAddingParagraph.state;
    openPageViewModalRef.current = openPageViewModal.state;
    openConfirmModalRef.current = openConfirmModal.state;
    openWarningModalRef.current = openWarningModal.state;
    showNoisyModalRef.current = showNoisyModal.state;
    openCreateThreadModalRef.current = openCreateThreadModal.state;
    openGuideLineModalRef.current = openGuideLineModal.state;
    isEditingRef.current = isEditing.state;
  }, [
    openWaveEditModal.state,
    isAddingParagraph.state,
    openPageViewModal.state,
    openConfirmModal.state,
    openWarningModal.state,
    showNoisyModal.state,
    openCreateThreadModal.state,
    openGuideLineModal.state,
    isEditing.state,
  ]);

  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      isAuthor && isRecordingEnabled.update(true);
      fetchBookDetails();
      context.update(getContext());
    }

    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        BookStatusCodes.bookRecording,
        bookDetails.state,
        bookDetails.state.pages.length,
        "Currently, this book is not in the recording stage.",
        isAuthor
      );
      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);
      if (!bookStatus.isOpenModal) {
        pages.update(bookDetails.state.pages);
        totalPages.update(bookDetails.state.pages.length);
      }
    }
  }, [bookDetails.state]);

  useEffect(() => {
    if (recordedUserId.state) {
      checkIsRecordedUserIsAdmin();
    }
  }, [recordedUserId.state]);

  useEffect(() => {
    if (selectedParagraphIndex.state) {
      const index = selectedParagraphIndex.state - 1;
      recordedUserId.update(pageContents.state[index]?.recorderUserId);
      if (pageContents.state.length > 0) {
        textToRecord.update(pageContents.state[index].paragraphText);
        let selected = {
          paragraphId: pageContents.state[index].paragraphId,
          orderIndex: pageContents.state[index].orderIndex,
          paragraphText: pageContents.state[index].paragraphText,
          audioUrl: pageContents.state[index].audioUrl,
          isRecorded: pageContents.state[index].isRecorded,
          aiAudioOptimization: pageContents.state[index].aiAudioOptimization,
        };
        pauseAudio();
        selectedParagraph.update(selected);
        prepareAudio(selected);
        if (
          paragraphParam.state &&
          paragraphParam.state > 0 &&
          paragraphParam.state <= pageContents.state.length
        ) {
          selectedParagraphIndex.update(paragraphParam.state);
          paragraphParam.update(0);
        }
      } else {
        textToRecord.update("");
      }
    }
  }, [selectedParagraphIndex.state, pageContents.state]);

  useEffect(() => {
    const { state: pageList } = pages;

    if (!pageList) return;

    if (!selectedIndex.state) {
      selectedIndex.update(1);
    }

    let completedCount = 0;
    const pagesWithoutRecording = [];

    pageList.forEach(({ isRecorded, pageNumber }) => {
      if (isRecorded) {
        completedCount++;
      } else {
        pagesWithoutRecording.push(pageNumber);
      }
    });

    pagesNotRecorded.update(pagesWithoutRecording);

    const totalPages = pageList.length;
    const completionPercentage = (completedCount / totalPages) * 100;

    const allKeyPagesRecorded =
      completionPercentage > RECORDING_PERCENTAGE_THRESHOLD &&
      (pageList[0].pageImageUrl.includes("credit_pages")
        ? pageList[0].isRecorded
        : true) &&
      (pageList[1].pageImageUrl.includes("credit_pages")
        ? pageList[1].isRecorded
        : true) &&
      (pageList[totalPages - 1].pageImageUrl.includes("credit_pages")
        ? pageList[totalPages - 1].isRecorded
        : true);

    isRecordingCompleted.update(allKeyPagesRecorded);
    showPagesType.update(false);
  }, [pages.state]);

  useEffect(() => {
    if (
      selectedParagraph.state &&
      pageParam.state &&
      pageParam.state > 0 &&
      pageParam.state <= pages.state.length
    ) {
      selectedIndex.update(pageParam.state);
      pageParam.update(0);
    }
  }, [selectedParagraph.state]);

  useEffect(() => {
    textToRecord.update("");
    isFetchingPageContent.update(true);
    if (selectedIndex.state) {
      if (
        showPagesType.state &&
        pages.state[selectedIndex.state - 1].isRecorded
      ) {
        let nextIndex = selectedIndex.state;
        while (
          nextIndex <= pages.state.length &&
          pages.state[nextIndex - 1].isRecorded
        ) {
          nextIndex++;
        }
        if (nextIndex <= pages.state.length) {
          selectedIndex.update(nextIndex);
        } else {
          selectedIndex.update(1);
        }
      } else {
        selectedParagraphIndex.update(1);
        isParagraphActionsEnabled.update(
          isActionsEnabled(selectedIndex.state - 1)
        );
        fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
        pauseAudio();
      }
    }
  }, [selectedIndex.state]);

  useEffect(() => {
    if (pageContents.state.length > 0 && !selectedParagraphIndex.state) {
      selectedParagraphIndex.update(1);
    }
    if (isDeleting.state) {
      checkIsRecorded();
      isDeleting.update(false);
    }
  }, [pageContents.state]);

  useEffect(() => {
    if (showPagesType.state) {
      let conditionMet = false;
      pages.state.forEach((page, index) => {
        if (!page.isRecorded && !conditionMet) {
          selectedIndex.update(index + 1);
          conditionMet = true;
        }
      });
    }
  }, [showPagesType.state]);

  const isActionsEnabled = (index) => {
    // if (index === 0 && pages[0]?.pageImageUrl?.includes("credit_pages")) {
    //   return false;
    // }
    // if (index === 1 && pages[1]?.pageImageUrl?.includes("credit_pages")) {
    //   return false;
    // }
    // if (
    //   index === pages.length - 1 &&
    //   pages[pages.length - 1]?.pageImageUrl?.includes("credit_pages")
    // ) {
    //   return false;
    // }
    // return true;

    if (pages.state[index]?.pageImageUrl?.includes("credit_pages")) {
      return false;
    }

    return true;
  };

  const handleKeyPress = (event) => {
    const blockedStates = [
      openWaveEditModalRef.current,
      isAddingParagraphRef.current,
      openPageViewModalRef.current,
      openConfirmModalRef.current,
      openWarningModalRef.current,
      showNoisyModalRef.current,
      openCreateThreadModalRef.current,
      openGuideLineModalRef.current,
      isEditingRef.current,
    ];

    if (!blockedStates.some((state) => state)) {
      if (
        event.keyCode === 32 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 40
      ) {
        event.preventDefault();
        keyPressed.update(event.keyCode);
      }
    }
  };

  return (
    <div tabIndex={0}>
      <div style={{ position: "relative" }}>
        <Tooltip
          placement="top"
          title={isHideLeft.state ? "Show Page View" : "Hide Page View"}
        >
          <Fab
            color="primary"
            size="small"
            className={classes2.fab}
            onClick={() => {
              isHideLeft.update(!isHideLeft.state);
            }}
          >
            {isHideLeft.state ? (
              <KeyboardDoubleArrowRightIcon />
            ) : (
              <KeyboardDoubleArrowLeftIcon />
            )}
          </Fab>
        </Tooltip>
      </div>
      <Box px={5}>
        {!isFetching.state && (
          <Grid container spacing={5}>
            {!isHideLeft.state && (
              <Grid container item xs={2} pb={10}>
                <Stack spacing={4}>
                  <Box
                    style={{
                      display: "flex",
                      border: "2px solid #ccc",
                      borderRadius: 10,
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showPagesType.state}
                          color="success"
                          onChange={(e) =>
                            showPagesType.update(e.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography variant="font15b" color={COLORS.primary}>
                          View Unrecorded
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </Box>

                  <Grid
                    container
                    justifyContent="center"
                    className={classes.recording}
                    sx={{
                      maxHeight: isToolbarHidden.state ? "80vh" : "60vh",
                    }}
                  >
                    <LeftScrollablePageView
                      isDisabled={isRecording.state || isAudioUploading.state}
                    />
                  </Grid>
                </Stack>
              </Grid>
            )}

            <Grid item xs={isHideLeft.state ? 10 : 8}>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  <Box>
                    <ContentView
                      {...{
                        isToolbarHidden,
                      }}
                    />
                    <RecordDiv />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ZoomInAndOut />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={2}
              className={classes.recording}
              sx={{ maxHeight: isToolbarHidden.state ? "100vh" : "80vh" }}
              pb={10}
              pr={2}
            >
              {isFetchingPageContent.state ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="70vh"
                  style={{ borderRadius: 10 }}
                />
              ) : (
                <RightScrollableParagraphView
                  isDisabled={isRecording.state || isAudioUploading.state}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      <RecordPageFooter
        isDisabled={isRecording.state || isAudioUploading.state}
      />

      <PageViewModal open={openPageViewModal} pageUrl={pageUrl.state} />
      <ConfirmModal {...{ openConfirmModal, confirmModalData }} />
      <WarningModal
        {...{ openWarningModal, warningModalMessage, navigateTo }}
      />
      <NoisyModal {...{ showNoisyModal, noisyModalMessage, noisyModalType }} />
      <RecordGuideLine {...{ openGuideLineModal }} />
      <UnRecordedPagesModal
        {...{
          openUnRecordedPagesModal,
          pages,
          selectedIndex,
          pagesNotRecorded,
          callUpdateBookStatus,
        }}
      />

      <CreateThreadModal
        open={openCreateThreadModal}
        userId={recordedUserId.state}
        {...{
          threadTitle,
          description,
          message,
          threadPage,
          threadParagraph,
          bookId,
        }}
      />

      <WaveEditModal open={openWaveEditModal} />
    </div>
  );
}

export default Voices;
