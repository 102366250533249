import React, { useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { THREAD_TYPES as threadTypes, COLORS } from "constants";
import {
  getComments,
  getThreads,
  markThreadAsRead,
  sendComment,
} from "service/ChatService";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import ThreadsListArea from "./sections/ThreadsListArea";
import ThreadsCommentSection from "./sections/ThreadCommentSection";
import { CreateThreadModal } from "components/modals";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { capitalize } from "utils/WordsUtils";

function Threads({ isHideSideMenu }) {
  const first_page_number = 1;
  const scrollableRef = useRef(null);
  const threads = useStateUpdate(null);
  const selectedThreadId = useStateUpdate(null);
  const threadTitleForDisplay = useStateUpdate("");
  const threadPageUri = useStateUpdate("");
  const threadUserForDisplay = useStateUpdate("");
  const comments = useStateUpdate([]);
  const totalCommentsPages = useStateUpdate(null);
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(0);
  const isFetchingComments = useStateUpdate(true);
  const newComment = useStateUpdate("");
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const threadTitle = useStateUpdate("");
  const description = useStateUpdate("");
  const message = useStateUpdate("");
  const openCreateThreadModal = useStateUpdate(false);
  const threadType = useStateUpdate(threadTypes.general);

  useEffect(() => {
    selectedThreadId.update(null);
    fetchThreads();
  }, [threadType.state]);

  useEffect(() => {
    const scrollableElement = scrollableRef.current;

    const handleScroll = () => {
      if (scrollableElement) {
        const scrollTop = scrollableElement.scrollTop;
        scrollTop === 0 && page.update((prevPage) => prevPage + 1);
      }
    };

    scrollableElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollDown = () => {
    if (scrollableRef.current) {
      const scrollOptions = {
        behavior: "smooth",
      };
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      scrollableRef.current.scrollIntoView(scrollOptions);
    }
  };

  useEffect(() => {
    isHideSideMenu.update(isAuthorOrEngineer);
  }, [isAuthorOrEngineer]);

  useEffect(() => {
    const firstThread = threads.state?.[0];

    if (firstThread) {
      if (!selectedThreadId.state) {
        selectedThreadId.update(firstThread.threadId);
        threadTitleForDisplay.update(
          `${firstThread.title} #${firstThread.threadId}`
        );
        threadPageUri.update(firstThread.pageUri);
        !isAuthorOrEngineer &&
          threadUserForDisplay.update(`${capitalize(firstThread.fullName)}`);
      }

      if (!firstThread.isRead) {
        callMarkThreadAsRead(firstThread.threadId);
      }
    }
  }, [threads.state]);

  useEffect(() => {
    selectedThreadId.state && loadLatestComments();
  }, [selectedThreadId.state]);

  useEffect(() => {
    page.state > 1 && fetchComments();
  }, [page.state]);

  const fetchThreads = async () => {
    let result = await getThreads(
      isAuthorOrEngineer ? threadTypes.all : threadType.state
    );
    if (result.status === 200) {
      newComment.update("");
      threads.update(result.data.data);
    }
  };

  const loadLatestComments = () => {
    page.update(first_page_number);
    fetchComments(first_page_number);
    setTimeout(scrollDown, 1000);
  };

  const fetchComments = async (pageNumber) => {
    isFetchingComments.update(true);

    try {
      const params = buildCommentsParams(pageNumber);
      const result = await getComments(selectedThreadId.state, params);

      if (result.status === 200) {
        updateComments(result.data.data.threadMessages, pageNumber);
        totalCommentsPages.update(result.data.data.pagination.totalPages);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      isFetchingComments.update(false);
    }
  };

  const buildCommentsParams = (pageNumber) => {
    const pageToFetch = pageNumber ?? page.state;
    return `?page=${pageToFetch}&limit=${rowsPerPage.state}&sort=DESC&filter=`;
  };

  const updateComments = (newComments, pageNumber) => {
    if (pageNumber === 1 || page.state === 1) {
      comments.update(newComments);
    } else {
      comments.update([...comments.state, ...newComments]);
    }
  };

  const callMarkThreadAsRead = async (threadId) => {
    let result = await markThreadAsRead(threadId);
    if (result.status === 200) {
      fetchThreads();
    }
  };

  const callSendComment = async () => {
    const body = {
      message: newComment.state,
      threadId: selectedThreadId.state,
    };
    newComment.update("");
    let result = await sendComment(body);
    result.status === 200 && loadLatestComments();
  };

  return (
    <Box
      ml={{ xs: 2, md: 3 }}
      mr={{ xs: 2, md: 5 }}
      mt={isAuthorOrEngineer && 4}
    >
      <CreateThreadModal
        open={openCreateThreadModal}
        {...{ threadTitle, description, message, fetchThreads }}
      />
      <Grid container>
        <Grid container item xs={12} mb={2} alignItems="center">
          <Typography variant="font20b" color={COLORS.primary}>
            Threads
          </Typography>
          <Tooltip title="Create new threads">
            <IconButton
              size="small"
              onClick={(e) => {
                openCreateThreadModal.update(true);
              }}
            >
              <AddCircleRoundedIcon sx={{ color: COLORS.primary }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Paper variant="outlined" sx={{ width: "100%", borderRadius: "20px" }}>
          <Grid container item xs={12}>
            <ThreadsListArea
              {...{
                threads,
                selectedThreadId,
                threadTitleForDisplay,
                threadPageUri,
                threadUserForDisplay,
                callMarkThreadAsRead,
                threadType,
                isAuthorOrEngineer,
              }}
            />

            <ThreadsCommentSection
              {...{
                threadTitleForDisplay,
                threadPageUri,
                threadUserForDisplay,
                scrollableRef,
                totalCommentsPages,
                page,
                isFetchingComments,
                comments,
                newComment,
                callSendComment,
                loadLatestComments,
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}

export default Threads;
