import React from "react";
import { GroupingPageContext } from "./context";
import { useStateUpdate } from "UseStateUpdate";
import { useNavigate, useParams } from "react-router-dom";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { resetChapter } from "service/BookService";
import { getChapters } from "service/BookService";
import { createChapter, AutoGroupPages } from "service/BookService";
import { getBookDetails } from "service/BookService";
import { updateBookStatus } from "service/BookService";

// Create the provider component
const GroupingPageContextProvider = ({ children }) => {
  const { bookId } = useParams();
  const navigate = useNavigate();
  const pagesToDisplay = useStateUpdate([]);
  const isShowingGroupedPages = useStateUpdate(false);
  const selectedFirstPage = useStateUpdate();
  const selectedLastPage = useStateUpdate();
  const pageUrl = useStateUpdate("");
  const openPageViewModal = useStateUpdate(false);
  const chapters = useStateUpdate([]);
  const navigateTo = useStateUpdate("");
  const openConfirmModal = useStateUpdate(false);
  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });
  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };
  const openGuideLineModal = useStateUpdate(false);
  const isFetching = useStateUpdate(true);
  const fetchedPages = useStateUpdate([]);

  const openWarningModal = useStateUpdate(false);
  const warningModalMessage = useStateUpdate("");
  const isPagesFetched = useStateUpdate(false);
  const bookDetails = useStateUpdate(null);
  const isAutoGrouping = useStateUpdate(false);

  const viewPage = (url) => {
    pageUrl.update(url);
    openPageViewModal.update(true);
  };

  const showChapterPages = (index) => {
    pagesToDisplay.update(chapters.state[index].pages);
    isShowingGroupedPages.update(true);
  };

  const callResetChapters = async (start, end) => {
    let startChapterNumber = start;
    let endChapterNumber = end;
    if (!startChapterNumber) {
      startChapterNumber = chapters.state[1].chapterNumber;
      endChapterNumber =
        chapters.state[chapters.state.length - 2].chapterNumber;
    }
    const body = {
      startChapterNumber: startChapterNumber,
      endChapterNumber: endChapterNumber,
    };
    const result = await resetChapter(bookId, body);
    if (result.status === 200) {
      selectedFirstPage.update();
      selectedLastPage.update();
      fetchGroupedChapters();
      openConfirmModal.update(false);
    }
  };

  const fetchGroupedChapters = async () => {
    isFetching.update(true);
    let result = await getChapters(bookId);
    if (result.status === 200) {
      const data = result.data.data;
      chapters.update(data.chapters);
      fetchedPages.update(data.pages);
    }
    isFetching.update(false);
  };

  const callAddNewChapter = async () => {
    const body = {
      chapterName: generateChapterName(),
      chapterNumber: selectedFirstPage.state,
      startPageNumber: selectedFirstPage.state,
      endPageNumber: selectedLastPage.state,
    };

    const result = await createChapter(bookId, body);
    if (result.status === 200) {
      selectedFirstPage.update();
      selectedLastPage.update();
      fetchGroupedChapters();
    }
  };

  const callAutoGroupPages = async () => {
    const body = {};
    isAutoGrouping.update(true);
    openConfirmModal.update(false);
    const result = await AutoGroupPages(bookId, body);
    if (result.status === 200) {
      selectedFirstPage.update();
      selectedLastPage.update();
      fetchGroupedChapters();
    }
    isAutoGrouping.update(false);
  };
  const fetchBookDetails = async () => {
    isPagesFetched.update(false);
    const result = await getBookDetails(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const generateChapterName = () => {
    const lastChapter = chapters.state[chapters.state.length - 2];
    const latestNumber = parseInt(lastChapter.chapterName.split(" ")[1]);
    const nextChapterNumber =
      lastChapter.chapterNumber > 1 ? latestNumber + 1 : 1;
    const chapterName = `Chapter ${nextChapterNumber}`;

    return chapterName;
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.chapterGrouped };
    const result = await updateBookStatus(bookId, body);
    result.status === 200 &&
      navigate(`/projects/${bookDetails.state.projectId}`);
  };

  return (
    <GroupingPageContext.Provider
      value={{
        pagesToDisplay,
        isShowingGroupedPages,
        selectedFirstPage,
        selectedLastPage,
        bookId,
        chapters,
        openWarningModal,
        warningModalMessage,
        navigateTo,
        bookDetails,
        BookStatusCodes,
        fetchedPages,
        openGuideLineModal,
        openConfirmModal,
        openPageViewModal,
        confirmModalData,
        pageUrl,
        isFetching,
        viewPage,
        showChapterPages,
        setConfirmModalData,
        callResetChapters,
        fetchBookDetails,
        fetchGroupedChapters,
        callUpdateBookStatus,
        callAddNewChapter,
        callAutoGroupPages,
        isAutoGrouping,
      }}
    >
      {children}
    </GroupingPageContext.Provider>
  );
};

export default GroupingPageContextProvider;
