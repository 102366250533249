import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";

import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  Grid,
  CircularProgress,
  Alert,
} from "@mui/material";

import { isEmptyString, isValidEmail } from "utils/FormValidator";
import { COLORS } from "constants";
import { sendRecoveryMail } from "service/AuthService";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const email = useStateUpdate("");
  const isMailSend = useStateUpdate(false);
  const isLoader = useStateUpdate(false);
  const isErrorOccured = useStateUpdate(false);

  const validationError = useStateUpdate({
    email: "",
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      email: "",
    };
    if (isEmptyString(email.state)) {
      newValidationError.email = "Email is required";
      isValidated = false;
    } else if (!isValidEmail(email.state)) {
      newValidationError.email = "Email is invalid.";
      isValidated = false;
    }
    validationError.update(newValidationError);
    return isValidated;
  }

  async function sendMail() {
    isLoader.update(true);

    const result = await sendRecoveryMail(email.state);
    if (result.status === 200) {
      isMailSend.update(true);
    } else {
      isErrorOccured.update(true);
    }

    isLoader.update(false);
  }

  return (
    <Container maxWidth="xs">
      <Paper>
        <Box mt={10} py={5} px={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={1}
          >
            <Typography variant="font20b">Forgot Password?</Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={12}
            mb={3}
          >
            <Typography
              variant="font14"
              color={isMailSend.state ? "error" : COLORS.black}
            >
              {isMailSend.state
                ? `Please check your email (${email.state}) to reset password`
                : "Please enter your email address to send password reset link."}
            </Typography>
          </Grid>

          {!isMailSend.state && (
            <Fragment>
              <Grid item xs={12} mb={3}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="standard"
                  color="primary"
                  value={email.state}
                  inputProps={{ maxLength: 50 }}
                  error={!!validationError.state.email}
                  helperText={
                    <Typography variant="font14" color="error">
                      {validationError.state.email}
                    </Typography>
                  }
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      email: "",
                    });
                    email.update(e.target.value);
                  }}
                />
              </Grid>
              {isErrorOccured.state && (
                <Grid item xs={12} mb={3}>
                  <Alert severity="error">error : Try again!</Alert>
                </Grid>
              )}
            </Fragment>
          )}

          {isMailSend.state ? (
            <Grid container justifyContent="center">
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate("/", { replace: true })}
              >
                <Typography variant="buttonLabel">Home</Typography>
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" alignItems="center">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/login", { replace: true })}
                >
                  <Typography variant="buttonLabel">Cancel</Typography>
                </Button>
                <Button
                  disabled={isLoader.state}
                  variant="contained"
                  onClick={(e) => {
                    checkValidatorOnSubmit() && sendMail();
                  }}
                >
                  <Typography variant="containedbuttonLabel">Send</Typography>
                  {isLoader.state && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Grid>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgotPassword;
