import React, { useContext, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material/";
import { largeModalStyle } from "components/common/Styles";
import CloseIcon from "@mui/icons-material/Close";

import "css/voicesPage.css";

import { useStateUpdate } from "UseStateUpdate";
import WaveSurfer from "wavesurfer.js";
import * as TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import * as RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js";
import clipworker_script from "../Voices/sections/clip_worker.js";
import worker_script from "../Voices/sections/worker.js";
import util from "audio-buffer-utils";
import { getAudioBuffer } from "../Voices/sections/utils.js";
import { ThreeDots } from "react-loader-spinner";
import { COLORS } from "constants";
import { getAudioUrl } from "utils/GetUrl.js";
import { RecordingPageContext } from "context/context.js";

let lamejs = require("lamejs");
let waveSurfer;

function WaveEditModal(props) {
  const { open } = props;
  const {
    regionStart,
    regionEnd,
    silenceCount,
    waveLoading,
    processingWave,
    clippingWave,
    wavePlaying,
    checkNewSilence,
    silenceSeconds,
    silencedWave,
    editedWaves,
    waveEdited,
    disableClip,
    selectedParagraph,
    context,
    waveModal,
    prepareAudio,
    onUpload,
    pages,
    selectedIndex,
  } = useContext(RecordingPageContext);
  const modalLoaded = useStateUpdate(false);

  useEffect(() => {
    modalLoaded.state && onWaveModalOpen();
  }, [modalLoaded.state]);

  const renderWaveModal = () => {
    let regionStartValue;
    let regionEndValue;
    if (regionStart.state > 60) {
      regionStartValue = millisToMinutesAndSeconds(regionStart.state * 1000);
    } else {
      regionStartValue = `${regionStart.state.toFixed(2)} sec`;
    }
    if (regionEnd.state > 60) {
      regionEndValue = millisToMinutesAndSeconds(regionEnd.state * 1000);
    } else {
      regionEndValue = `${regionEnd.state.toFixed(2)} sec`;
    }
    let desilencetext =
      silenceCount.state > 0
        ? "CLICK De-silence to remove"
        : "No silences found";

    return (
      <Grid
        item
        xs={12}
        onLoad={() => {
          modalLoaded.update(true);
        }}
      >
        <Box p={0.5} className="voices_waveandbuttons_container">
          <Box className="voices_wavecontainer">
            <div id="waveform"></div>
            <div id="waveform_timeline"></div>
            {waveLoading.state && (
              <ThreeDots height="30" color={COLORS.primary} />
            )}
          </Box>

          <Box
            className={
              waveLoading.state || processingWave.state || clippingWave.state
                ? "voices_wave_buttonscontainer disabled_div"
                : "voices_wave_buttonscontainer "
            }
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "80%",
              }}
            >
              {wavePlaying.state === false ? (
                <Tooltip title="Play">
                  <div className="wave_playButton" onClick={(e) => playWave(e)}>
                    <img
                      className="wave_play"
                      src={require("assets/images/play_white.png")}
                      alt="Wave play"
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Pause">
                  <div className="wave_playButton" onClick={(e) => pauseWave()}>
                    <img
                      className="wave_play"
                      src={require("assets/images/pause_white.png")}
                      alt="pause"
                    />
                  </div>
                </Tooltip>
              )}
              {processingWave.state ? (
                <div
                  onClick={() => doNothing()}
                  className={
                    processingWave.state
                      ? "wave_playButton disabled_div"
                      : "wave_playButton"
                  }
                >
                  <ThreeDots height="10" color={COLORS.white} />
                </div>
              ) : (
                <Tooltip
                  title={
                    checkNewSilence.state ? (
                      <div className="voices_wave_popoverdiv">
                        Click to check silences
                      </div>
                    ) : (
                      <div className="voices_wave_popoverdiv">
                        {`We've discovered ${silenceCount.state} silences of ${silenceSeconds.state} seconds in the audio.`}
                        <div
                          style={{ fontWeight: "bold", color: COLORS.green }}
                        >
                          {desilencetext}
                        </div>
                      </div>
                    )
                  }
                >
                  <div
                    onClick={() => {
                      checkNewSilence.state
                        ? checkSilence()
                        : silenceCount.state === 0
                        ? doNothing()
                        : loadSilencedWave();
                    }}
                    className={
                      silencedWave.state != null &&
                      silencedWave.state ===
                        editedWaves.state[editedWaves.state.length - 1]
                        ? "wave_playButton disabled_div"
                        : silenceCount.state > 0
                        ? "wave_playButton wave_playButton_active"
                        : "wave_playButton"
                    }
                  >
                    {checkNewSilence.state
                      ? "De-silence"
                      : `De-silence(${silenceCount.state})`}
                  </div>
                </Tooltip>
              )}
              <Box
                style={{ pointerEvents: "none" }}
                className={"wave_playButton"}
              >
                {`Start: ${regionStartValue}`}
              </Box>
              <Box
                style={{ pointerEvents: "none" }}
                className={"wave_playButton"}
              >
                {`End: ${regionEndValue}`}
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "30%",
              }}
            >
              <Stack direction="row" spacing={1}>
                <Button
                  disabled={!waveEdited.state}
                  type="submit"
                  variant="contained"
                  onClick={(e) => {
                    undoChanges();
                  }}
                  style={{
                    background: "#fff",
                    borderRadius: "100px",
                    padding: "10px 0px",
                  }}
                >
                  <Typography variant="font12b" color="#000" mx={3} noWrap>
                    Undo clip
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  disabled={clippingWave.state || disableClip.state}
                  size="small"
                  onClick={(e) => {
                    clipFile();
                  }}
                  style={{
                    background: "#fff",
                    borderRadius: "100px",
                    padding: "10px 0px",
                  }}
                >
                  <Typography variant="font12b" color="#000" mx={3}>
                    Clip
                  </Typography>
                  {clippingWave.state && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Button>

                <Button
                  disabled={!(waveEdited.state && editedWaves.state.length > 0)}
                  type="submit"
                  variant="contained"
                  onClick={(e) => {
                    uploadEditedFile();
                  }}
                  style={{
                    background: "#fff",
                    borderRadius: "100px",
                    padding: "10px 0px",
                  }}
                >
                  <Typography variant="font12b" color="#000" mx={3}>
                    Save
                  </Typography>
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  const clipFile = () => {
    clippingWave.update(true);
    let url;
    let tempWaves;
    let waveToLoad;
    if (editedWaves.state.length > 0) {
      tempWaves = editedWaves.state;
      waveToLoad = tempWaves[tempWaves.length - 1];
      url = URL.createObjectURL(waveToLoad);
    } else {
      url =
        getAudioUrl(selectedParagraph.state.audioUrl) +
        "?t=" +
        new Date().getTime();
    }
    return getAudioBuffer(url, context.state)
      .then((response) => {
        let myClipWorker = new Worker(clipworker_script);
        let channelData = response.getChannelData(0);
        let sampleRate = response.sampleRate;
        let duration = response.duration;
        myClipWorker.postMessage({
          channelData: channelData,
          sampleRate: sampleRate,
          duration: duration,
          start: regionStart.state,
          end: regionEnd.state,
        });
        myClipWorker.onmessage = (m) => {
          let mp3Data = [];
          let a = util.create(m.data.channelData, 1, context.state.sampleRate);
          let dataAsInt16Array = floatArray2Int16(a.getChannelData(0));
          let mp3encoder = new lamejs.Mp3Encoder(
            a.numberOfChannels,
            a.sampleRate,
            320
          );
          let mp3Tmp = mp3encoder.encodeBuffer(dataAsInt16Array);
          mp3Data.push(mp3Tmp);
          mp3Tmp = mp3encoder.flush();
          mp3Data.push(mp3Tmp);

          const fileName = `${pages.state[selectedIndex.state - 1].pageId}-${
            selectedParagraph.state.paragraphId
          }.mp3`;
          const file = new File(mp3Data, fileName, {
            type: `audio/mp3`,
            lastModified: Date.now(),
          });
          const fileUrl = URL.createObjectURL(file);
          waveSurfer.destroy();
          loadWavesurfer(fileUrl);
          checkNewSilence.update(true);
          clippingWave.update(false);
          disableClip.update(a.duration < 1.5 ? true : false);
          regionStart.update(0);
          regionEnd.update(1);
          pushToEditedWaves(file);
          myClipWorker.terminate();
        };
      })
      .catch((err) => console.log(err));
  };

  const uploadEditedFile = () => {
    let waveToSave = editedWaves.state[editedWaves.state.length - 1];
    prepareAudio(selectedParagraph);
    closeWaveModal();
    onUpload(waveToSave, 1);
  };

  const floatArray2Int16 = (floatbuffer) => {
    let int16Buffer = new Int16Array(floatbuffer.length);
    for (let i = 0, len = floatbuffer.length; i < len; i++) {
      if (floatbuffer[i] < 0) {
        int16Buffer[i] = 0x8000 * floatbuffer[i];
      } else {
        int16Buffer[i] = 0x7fff * floatbuffer[i];
      }
    }
    return int16Buffer;
  };

  const checkSilence = () => {
    let url;
    if (editedWaves.state.length > 0) {
      let tempWaves = editedWaves.state;
      let waveToLoad = tempWaves[tempWaves.length - 1];
      url = URL.createObjectURL(waveToLoad);
    } else {
      url =
        getAudioUrl(selectedParagraph.state.audioUrl) +
        "?t=" +
        new Date().getTime();
    }
    trimAudioBuffer(url);
  };

  const trimAudioBuffer = async (url) => {
    processingWave.update(true);
    let firstIndex, lastIndex, arrayLength, anotherBufferArray;
    return getAudioBuffer(url, context.state)
      .then((response) => {
        let channelData = response.getChannelData(0);
        for (let i in channelData) {
          if (channelData[i] !== 0) {
            firstIndex = i;
            break;
          }
        }
        for (let j = response.length - 1; j >= 0; j--) {
          if (channelData[j] !== 0) {
            lastIndex = j;
            break;
          }
        }
        arrayLength =
          response.length -
          (response.length - parseFloat(lastIndex) + parseFloat(firstIndex));
        anotherBufferArray = new Float32Array(arrayLength);
        for (let k = firstIndex, m = 0; k <= lastIndex; k++, m++) {
          anotherBufferArray[m] = channelData[k];
        }
        detectSilence(anotherBufferArray);
      })
      .catch((err) => console.log(err));
  };

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const detectSilence = (channelData) => {
    let myWorker = new Worker(worker_script);
    myWorker.postMessage(channelData);
    myWorker.onmessage = (m) => {
      let mp3Data = [];
      let a = util.create(m.data.data, 1, context.state.sampleRate);
      let dataAsInt16Array = floatArray2Int16(a.getChannelData(0));
      let mp3encoder = new lamejs.Mp3Encoder(
        a.numberOfChannels,
        a.sampleRate,
        320
      );
      let mp3Tmp = mp3encoder.encodeBuffer(dataAsInt16Array);
      mp3Data.push(mp3Tmp);
      mp3Tmp = mp3encoder.flush();
      mp3Data.push(mp3Tmp);

      const fileName = `${pages.state[selectedIndex.state - 1].pageId}-${
        selectedParagraph.state.paragraphId
      }.mp3`;
      const file = new File(mp3Data, fileName, {
        type: `audio/mp3`,
        lastModified: Date.now(),
      });
      silenceCount.update(m.data.count);
      silenceSeconds.update(m.data.seconds);
      silencedWave.update(file);
      processingWave.update(false);
      checkNewSilence.update(false);
      disableClip.update(a.duration < 1.5 ? true : false);
      myWorker.terminate();
    };
  };

  const doNothing = () => {};

  const onWaveModalOpen = () => {
    let url =
      getAudioUrl(selectedParagraph.state.audioUrl) +
      "?t=" +
      new Date().getTime();
    trimAudioBuffer(url);
    loadWavesurfer(url);
  };

  const checkDisableClip = (url) => {
    return getAudioBuffer(url, context.state)
      .then((response) => {
        disableClip.update(response.duration < 1.5 ? true : false);
      })
      .catch((err) => console.log(err));
  };

  const undoChanges = () => {
    if (editedWaves.state.length === 0) {
      waveEdited.update(false);
    } else {
      let tempWaves = editedWaves.state;
      tempWaves.pop();
      waveSurfer.destroy();
      if (tempWaves.length === 0) {
        let url =
          getAudioUrl(selectedParagraph.state.audioUrl) +
          "?t=" +
          new Date().getTime();
        loadWavesurfer(url);
        waveEdited.update(false);
        checkNewSilence.update(true);
      } else {
        let waveToLoad = tempWaves[tempWaves.length - 1];
        const url = URL.createObjectURL(waveToLoad);
        checkDisableClip(url);
        loadWavesurfer(url);
        editedWaves.update(tempWaves);
        checkNewSilence.update(true);
      }
    }
  };

  const loadWavesurfer = (urlIs) => {
    waveLoading.update(true);
    waveSurfer = WaveSurfer.create({
      container: document.querySelector("#waveform"),
      waveColor: "#72F58E",
      progressColor: "#ffffff",
      plugins: [
        TimelinePlugin.create({
          container: "#waveform_timeline",
          notchPercentHeight: 90,
          primaryColor: "#FFFFFF",
          secondaryColor: "#FFFFFF",
          primaryFontColor: "#FFF",
          secondaryFontColor: "#FFF",
          primaryLabelInterval: 1,
          secondaryLabelInterval: 10,
        }),
        RegionsPlugin.create({
          regions: [
            {
              start: 0,
              end: 1,
              loop: false,
              color: "rgba(255, 255, 255, 0.059)",
            },
          ],
          maxRegions: 1,
          dragSelection: {
            slop: 5,
          },
        }),
      ],
    });
    waveSurfer.load(urlIs);
    waveSurfer.on("ready", () => {
      waveLoading.update(false);
      let region =
        waveSurfer.regions.list[Object.keys(waveSurfer.regions.list)[0]];
      let duration = waveSurfer.backend.buffer.duration;
      regionSet(region, duration);
    });
    waveSurfer.on("finish", () => {
      wavePlaying.update(false);
    });
    waveSurfer.on("region-update-end", (region) => {
      let duration = region.wavesurfer.backend.buffer.duration;
      regionSet(region, duration);
    });
  };

  const regionSet = (region, duration) => {
    let start = region.start < 0 ? 0 : region.start;
    let end = region.end > duration ? duration : region.end;
    if (duration - (region.end - region.start) < 2) {
      regionStart.update(start);
      regionEnd.update(end);
      disableClip.update(true);
    } else {
      regionStart.update(start);
      regionEnd.update(end);
      disableClip.update(false);
    }
  };

  const loadSilencedWave = () => {
    if (
      silencedWave.state != null &&
      silencedWave.state !== editedWaves.state[editedWaves.state.length - 1]
    ) {
      waveSurfer.destroy();
      const url = URL.createObjectURL(silencedWave.state);
      loadWavesurfer(url);
      pushToEditedWaves(silencedWave.state);
    }
  };

  const pushToEditedWaves = (file) => {
    let tempWaves = editedWaves.state;
    tempWaves.push(file);
    editedWaves.update(tempWaves);
    waveEdited.update(true);
    regionStart.update(0);
    regionEnd.update(1);
    checkNewSilence.update(true);
  };

  const closeWaveModal = () => {
    pauseWave();
    waveSurfer?.destroy();
    modalLoaded.update(false);
    waveModal.update(false);
    waveLoading.update(true);
    wavePlaying.update(false);
    editedWaves.update([]);
    waveEdited.update(false);
    silenceCount.update(0);
    silenceSeconds.update(0);
    checkNewSilence.update(true);
    open.update(false);
  };

  const playWave = (e) => {
    waveSurfer.play();
    wavePlaying.update(true);
  };

  const pauseWave = () => {
    waveSurfer.pause();
    wavePlaying.update(false);
  };

  return (
    <Modal open={open.state} onClose={closeWaveModal}>
      <Box sx={largeModalStyle}>
        <Grid container rowSpacing={2}>
          <Grid item xs={2}></Grid>
          <Grid
            item
            xs={8}
            container
            alignContent="center"
            justifyContent="center"
          >
            <Typography variant="font20b">Audio edit</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            alignContent="center"
            justifyContent="flex-end"
          >
            <IconButton onClick={closeWaveModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="font15">Text</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid #000",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="font15">
                {selectedParagraph.state &&
                  selectedParagraph.state.paragraphText}
              </Typography>
            </Box>
          </Grid>

          {context.state !== null && open.state ? renderWaveModal() : null}
        </Grid>
      </Box>
    </Modal>
  );
}

export default WaveEditModal;
