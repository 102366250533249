import React, { Fragment } from "react";
import { isValidPassword } from "utils/FormValidator";
import {
  Typography,
  Grid,
  Divider,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Input,
  FormControl,
  InputLabel,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { iconButton } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import { changePassword } from "service/AuthService";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";

function PasswordInput({
  label,
  value,
  error,
  errorMessage,
  showPassword,
  onChange,
  onToggleVisibility,
}) {
  return (
    <FormControl fullWidth variant="standard" color="primary">
      <InputLabel error={error}>
        <Typography variant="font12">{label}</Typography>
      </InputLabel>
      <Input
        size="small"
        error={error}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onToggleVisibility}
              onMouseDown={(e) => e.preventDefault()}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={error}>
        {error && <Typography variant="font11">{errorMessage}</Typography>}
      </FormHelperText>
    </FormControl>
  );
}

function UserPassword({ calltoast }) {
  const isChangePassword = useStateUpdate(false);
  const currentPassword = useStateUpdate("");
  const showCurrentPassword = useStateUpdate(false);
  const newPassword = useStateUpdate("");
  const showNewPassword = useStateUpdate(false);
  const confirmNewPassword = useStateUpdate("");
  const showConfirmNewPassword = useStateUpdate(false);
  const validationError = useStateUpdate({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  function checkValidatorOnSubmit() {
    let isValidated = true;
    let newValidationError = {
      currentPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    };

    if (!isValidPassword(newPassword.state)) {
      newValidationError.newPassword = true;
      isValidated = false;
    } else if (newPassword.state !== confirmNewPassword.state) {
      newValidationError.confirmNewPassword = true;
      isValidated = false;
    }

    validationError.update(newValidationError);
    return isValidated;
  }

  function getPasswordData() {
    return {
      oldPassword: currentPassword.state,
      newPassword: newPassword.state,
    };
  }

  const callChangePassword = async () => {
    let data = getPasswordData();
    const result = await changePassword(data);
    if (result.status === 200) {
      calltoast("success", "Password has been changed.");
      toggleChangePassword();
    } else {
      validationError.update({
        ...validationError.state,
        currentPassword: true,
      });
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const toggleChangePassword = () => {
    currentPassword.update("");
    newPassword.update("");
    confirmNewPassword.update("");
    validationError.update({
      currentPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    });
    isChangePassword.update(!isChangePassword.state);
  };

  return (
    <Grid container>
      {isChangePassword.state ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PasswordInput
                label="Current Password*"
                value={currentPassword.state}
                error={validationError.state.currentPassword}
                errorMessage={"The password is not correct."}
                type={showCurrentPassword.state ? "text" : "password"}
                showPassword={showCurrentPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    currentPassword: false,
                  });
                  currentPassword.update(e.target.value);
                }}
                onToggleVisibility={() =>
                  showCurrentPassword.update(!showCurrentPassword.state)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                label="New Password*"
                value={newPassword.state}
                error={validationError.state.newPassword}
                errorMessage={"Password must contain a minimum of 8 characters"}
                type={showNewPassword.state ? "text" : "password"}
                showPassword={showNewPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    newPassword: false,
                  });
                  newPassword.update(e.target.value);
                }}
                onToggleVisibility={() =>
                  showNewPassword.update(!showNewPassword.state)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                label="Confirm Password*"
                value={confirmNewPassword.state}
                error={validationError.state.confirmNewPassword}
                errorMessage={"Password does not match"}
                type={showConfirmNewPassword.state ? "text" : "password"}
                showPassword={showConfirmNewPassword.state}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    confirmNewPassword: false,
                  });
                  confirmNewPassword.update(e.target.value);
                }}
                onToggleVisibility={() =>
                  showConfirmNewPassword.update(!showConfirmNewPassword.state)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      checkValidatorOnSubmit() && callChangePassword();
                    }}
                    sx={iconButton.border}
                  >
                    <DoneIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={toggleChangePassword}
                    sx={iconButton.border}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid item xs={6}>
            <Typography variant="font14">*********</Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                color="primary"
                size="small"
                onClick={toggleChangePassword}
              >
                <Typography variant="font11" noWrap>
                  Change password
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default UserPassword;
