import { apiCall } from "./AuthProvider";

const SERVICE_URL = "chat-service/";

export const sendComment = async (body) => {
  const url = `${SERVICE_URL}conversations`;
  return apiCall("post", url, body);
};

export const getThreads = async (threadType) => {
  const url = `${SERVICE_URL}conversations/threads/thread-type/${threadType}`;
  return apiCall("get", url);
};

export const getComments = async (threadId, searchParams) => {
  const url = `${SERVICE_URL}conversations/threads/${threadId}${searchParams}`;
  return apiCall("get", url);
};

export const markThreadAsRead = async (threadId) => {
  const url = `${SERVICE_URL}conversations/threads/${threadId}/read`;
  return apiCall("post", url, {});
};

export const getUnReadThreads = async () => {
  const url = `${SERVICE_URL}conversations/threads/unread`;
  return apiCall("get", url);
};
