import React from "react";

import SampleRecordingPage from "./sections/SampleRecordingPage";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import Record from "assets/images/mic.png";
import { sampleRecording } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";

function SampleRecording() {
  const classes = sampleRecording();
  const isRecordingPage = useStateUpdate(false);
  const isSilenceRecording = useStateUpdate(false);
  const isLeftHover = useStateUpdate(true);

  return isRecordingPage.state ? (
    <SampleRecordingPage {...{ isRecordingPage, isSilenceRecording }} />
  ) : (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          background: isLeftHover.state
            ? `linear-gradient(0deg, #6b7cd6 10%, #fff 100%)`
            : `linear-gradient(0deg, #ccc 30%, #fff 100%)`,
        }}
        onMouseEnter={() => {
          isLeftHover.update(true);
        }}
        onMouseLeave={() => {
          isLeftHover.update(false);
        }}
      >
        <Stack
          display="flex"
          height="85vh"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="font48">Sound</Typography>
          <Typography variant="font16" textAlign="center" width="75%">
            Record an audio sample to check your audio quality. Quality audio is
            important in building Audiobook.
          </Typography>
          <Box className={classes.recordButton} mt={5}>
            <IconButton
              size="large"
              onClick={(e) => {
                isSilenceRecording.update(false);
                isRecordingPage.update(true);
              }}
            >
              <img src={Record} width="70px" alt="Record" />
            </IconButton>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          background: isLeftHover.state
            ? `linear-gradient(0deg, #ccc 30%, #fff 100%)`
            : `linear-gradient(0deg, #6b7cd6 10%, #fff 100%)`,
        }}
        onMouseEnter={() => {
          isLeftHover.update(false);
        }}
        onMouseLeave={() => {
          isLeftHover.update(true);
        }}
      >
        <Stack
          display="flex"
          height="85vh"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="font48">Silence</Typography>
          <Typography variant="font16" textAlign="center" width="75%">
            Record a silence sample to optimize the recording experience. Check
            your environment silence before recording.
          </Typography>
          <Box className={classes.recordButton} mt={5}>
            <IconButton
              size="large"
              onClick={(e) => {
                isSilenceRecording.update(true);
                isRecordingPage.update(true);
              }}
            >
              <img src={Record} width="70px" alt="Record" />
            </IconButton>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default SampleRecording;
